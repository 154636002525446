import styled from "styled-components";

export const FooterStyle = styled.footer`
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    height: 80vh;
    background-color: #F4F5FF;
    padding-bottom: 1rem;
`
