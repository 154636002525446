import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AdminNav from '../components/AdminNav'
import Footer from '../components/Footer'
import NavAside from '../components/NavAside'
import { getCampaign, withdrawCampaign } from '../state/actions/admin.actions'
import useWindowSize from '../utils/useWindowSize'

const WithdrawCampaign = () => {

    const { width } = useWindowSize()
    const breakpoint = 620;

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { campaign } = useSelector(state => state.admin_campaign)
    const { requestStatus } = useSelector(state => state.admin_withdrawcampaign)

    useEffect(() => {
        dispatch(getCampaign(params.id))
    }, [dispatch, params, requestStatus, navigate])

    const onSubmit = (e) => {
        e.preventDefault()

        dispatch(withdrawCampaign(params.id))
        if (requestStatus === 'success') navigate('/admin/campaigns', { replace: true })
    }


    return (
        <div>
            {width < breakpoint ?
                <MobileContainer>
                    <AdminNav />
                    <Date>
                        <h5>Monday, 7th December 2021</h5>
                    </Date>

                    <main>
                        <h3>Withdraw Campaign</h3>
                        <ImageContainer>
                            <img src={campaign && campaign.imageUrl} alt="withdraw campaign" />
                        </ImageContainer>
                        <CampaignInfo>
                            <p>
                                <strong>Title: </strong>
                                {campaign && campaign.title}
                            </p>
                            <p>
                                <strong>Category: </strong>
                                {campaign && campaign.category}
                            </p>
                            <p>
                                <strong>Campaign Description: </strong>
                            </p>
                            <p>
                                {campaign && campaign.description}
                            </p>

                            <p>
                                <strong>Funding Goal: </strong>
                                {campaign && campaign.fundingGoal}
                            </p>
                            <p>
                                <strong>Total Amount Raised: </strong>
                                0.00 NGN
                            </p>
                            <p>
                                <strong>Total Amount of each 20% Deduction: </strong>
                                0.00 NGN
                            </p>
                            <p>
                                <strong>Total Withdrawable Amount: </strong>
                                0.00 NGN
                            </p>
                            <p>
                                <strong>Expiry Date: </strong>
                                {campaign && campaign.endDate.split('T')[0]}
                            </p>
                            <div className="buttons">
                                <button
                                    className="withdraw"
                                    type="submit"
                                    style={{ cursor: 'pointer' }}
                                    onClick={onSubmit}
                                >Withdraw</button>
                                <button className="cancel">Cancel</button>
                            </div>
                        </CampaignInfo>
                    </main>
                    <Footer />
                </MobileContainer>
                :
                <DesktopContainer>
                    <main>
                        <NavAside />
                        <MainSection>
                            <DesktopInfo>
                                <div>
                                    <h4>Hello!</h4>
                                    <p>
                                        20% of each donations funding your campaigns will be deducted for the support of the platform
                                    </p>
                                </div>
                                <div>
                                    <Date>
                                        <h5>Monday, 7th December 2021</h5>
                                    </Date>
                                </div>
                            </DesktopInfo>


                            <h3>Withdraw Campaign</h3>

                            <DesktopImageContainer>
                                <img src={campaign && campaign.imageUrl} alt="withdraw campaign" />
                            </DesktopImageContainer>

                            <CampaignInfo>
                                <p>
                                    <strong>Title: </strong>
                                    {campaign && campaign.title}
                                </p>
                                <p>
                                    <strong>Category: </strong>
                                    {campaign && campaign.category}
                                </p>
                                <p>
                                    <strong>Campaign Description: </strong>
                                </p>
                                <p>
                                    {campaign && campaign.description}
                                </p>

                                <p>
                                    <strong>Funding Goal: </strong>
                                    {campaign && campaign.fundingGoal}
                                </p>
                                <p>
                                    <strong>Total Amount Raised: </strong>
                                    0.00 NGN
                                </p>
                                <p>
                                    <strong>Total Amount of each 20% Deduction: </strong>
                                    0.00 NGN
                                </p>
                                <p>
                                    <strong>Total Withdrawable Amount: </strong>
                                    0.00 NGN
                                </p>
                                <p>
                                    <strong>Expiry Date: </strong>
                                    {campaign && campaign.endDate}
                                </p>
                                <div className="buttons">
                                    <button
                                        className="withdraw"
                                        type="submit"
                                        style={{ cursor: 'pointer' }}
                                        onClick={onSubmit}
                                    >Withdraw</button>
                                    <button className="cancel">Cancel</button>
                                </div>
                            </CampaignInfo>
                        </MainSection>
                    </main>
                    <Footer />
                </DesktopContainer>
            }
        </div>
    )
}

const MobileContainer = styled.div`
    max-width: 100%;

    main {
        h3 {
            text-align: center;
            color: #D9745B;
        }
    }
`

const Date = styled.div`
    text-align: center;
    h5 {
        color: #D9745B;
    }
`



const ImageContainer = styled.div`
    width: 100%;
    padding: 1rem 2rem;

    img {
        width: 100%;
    }
`


const CampaignInfo = styled.div`
    font-size: 0.8rem;
    padding: 0 2rem;

    @media(min-width: 620px) {
        font-size: 1rem;
    }

    strong {
        color: #D9745B;
    }

    .buttons {
        display: flex;
        flex-direction: column;

        @media(min-width: 620px) {
            width: 40%;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 2rem;
        }

        button {
            padding: 0.8rem;
            margin: 0.5rem 0;

            @media(min-width: 620px) {
                width: 100%;
                margin-right: 0.5rem;
            }
        }

        .withdraw {
            background-color: #404295;
            color: white;
            border: none;
        }
        .cancel {
            background: none;
            border: 1px solid #404295;
            color: #404295;
        }
    }
`

const DesktopContainer = styled.div`
    main {
        display: flex;
    }
`

const DesktopInfo = styled.div`
    display: flex;
    justify-content: space-between;
`

const DesktopImageContainer = styled.div`
    width: 100%;
    padding: 1rem 2rem;

    img {
        width: 100%;
    }
`

const MainSection = styled.section`
    margin-right: 2rem;
    width: 100%;

    h3 {
        text-align: center;
        color: #D9745B;
    }
`

export default WithdrawCampaign
