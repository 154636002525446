import React from 'react'
import styled from 'styled-components'
import { CampaignStyles } from './styles/Campaign'

const Campaign = ({img, campaign}) => {
    return (
        <>
            <CampaignStyles>
                <CampaignContainer>
                    <div className="campaign-image">
                        <img src={img} alt="campaign" />
                    </div>
                    <div className="campaign-info">
                        <p>
                            <strong>{campaign?.description}</strong>
                        </p>
                        <p>Funding Goal: #{campaign?.fundingGoal}</p>
                        {/* <p>Total Amount Raised: #{campaign.totalDonations}</p> */}
                        <p>Location: {campaign?.country}</p>
                        <p>Expiry Date: {campaign?.endDate.split('T')[0]}</p>
                    </div>
                </CampaignContainer>

                {/* <div>
                    <div>
                        <img src={img} alt="campaign" />
                    </div>
                    <div>
                        <h6>Help Johnny Green get rid of a hole in the heart</h6>
                        <p>Funding Goal: $2,000,000</p>
                        <p>Total Amount Raised: $10,000</p>
                        <p>Location: London, England</p>
                        <p>Expiry Date: Sun, 27th December, 2020</p>
                    </div>
                </div> */}
            </CampaignStyles>
        </>
    )
}

const CampaignContainer = styled.div`
    display: flex;

    .campaign-image {
        width: 40%;
        margin-right: 2rem;

        img {
            width: 100%;
        }
    }

    .campaign-info {
        width: 60%;
    }
`

export default Campaign
