import { createSlice } from "@reduxjs/toolkit"
import { adminSignup, adminSignin, getAdminDashboard, adminLogout, getCampaign, approveCampaign, withdrawCampaign, getDonations, getActivities } from "../actions/admin.actions";

const AdminInfoFromLS = localStorage.getItem('admin') ? JSON.parse(localStorage.getItem('admin')) : null
const initialState = {
    user: AdminInfoFromLS,
    activities: [],
    requestStatus: "",
    requestError: "",
}

const signinState = {
    user: {},
    requestStatus: "",
    requestError: "",
}
const signupState = {
    newUser: {},
    requestStatus: "",
    requestError: "",
}
const dashboardState = {
    campaigns: [],
    donations: [],
    totalDonations: 0,
    totolDeductions: 0,
    requestStatus: "",
    requestError: "",
}

const campaignState = {
    campaign: {},
    requestStatus: "",
    requestError: "",
}
const approveCampaignState = {
    campaign: {},
    requestStatus: "",
    requestError: "",
}
const withdrawCampaignState = {
    requestStatus: "",
    requestError: "",
}
const donationsState = {
    donations: [],
    requestStatus: "",
    requestError: "",
}






export const adminAuthSlice = createSlice({
    name: 'admin-logout',
    initialState,
    reducers: {},
    extraReducers: {
        [adminLogout.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [adminLogout.fulfilled]: (state, action) => {
            return {
                ...state,
                requestStatus: "success",
                requestError: ""
            }
        },
        [adminLogout.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})

export const adminSigninSlice = createSlice({
    name: 'admin-signin',
    initialState: signinState,
    reducers: {},
    extraReducers: {
        [adminSignin.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [adminSignin.fulfilled]: (state, action) => {
            return {
                ...state,
                user: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [adminSignin.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})

export const adminSignupSlice = createSlice({
    name: 'admin-signup',
    initialState: signupState,
    reducers: {},
    extraReducers: {
        [adminSignup.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [adminSignup.fulfilled]: (state, action) => {
            return {
                ...state,
                newUser: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [adminSignup.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})

export const adminDashboardSlice = createSlice({
    name: 'admin-dashboard',
    initialState: dashboardState,
    reducers: {},
    extraReducers: {
        [getAdminDashboard.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [getAdminDashboard.fulfilled]: (state, action) => {
            return {
                ...state,
                campaigns: action.payload.campaigns,
                donations: action.payload.donations,
                totalDonations: action.payload.totalDonations,
                totalDeductions: action.payload.totalDeductions,
                requestStatus: "success",
                requestError: ""
            }
        },
        [getAdminDashboard.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})


export const adminGetCampaignSlice = createSlice({
    name: 'admin-campaign',
    initialState: campaignState,
    reducers: {},
    extraReducers: {
        [getCampaign.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [getCampaign.fulfilled]: (state, action) => {
            return {
                ...state,
                campaign: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [getCampaign.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})


export const adminApproveCampaignSlice = createSlice({
    name: 'admin-approvecampaign',
    initialState: approveCampaignState,
    reducers: {},
    extraReducers: {
        [approveCampaign.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [approveCampaign.fulfilled]: (state, action) => {
            return {
                ...state,
                campaign: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [approveCampaign.rejected]: (state, action) => {
            console.log(action);
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})



export const adminWithdrawCampaignSlice = createSlice({
    name: 'admin-withdrawcampaign',
    initialState: withdrawCampaignState,
    reducers: {},
    extraReducers: {
        [withdrawCampaign.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [withdrawCampaign.fulfilled]: (state, action) => {
            return {
                ...state,
                requestStatus: "success",
                requestError: ""
            }
        },
        [withdrawCampaign.rejected]: (state, action) => {
            console.log(action);
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})



export const adminDonationsSlice = createSlice({
    name: 'admin-donations',
    initialState: donationsState,
    reducers: {},
    extraReducers: {
        [getDonations.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [getDonations.fulfilled]: (state, action) => {
            return {
                ...state,
                donations: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [getDonations.rejected]: (state, action) => {
            console.log(action);
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})


export const adminActivitiesSlice = createSlice({
    name: 'admin-activities',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [getActivities.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [getActivities.fulfilled]: (state, action) => {
            return {
                ...state,
                activities: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [getActivities.rejected]: (state, action) => {
            console.log(action);
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})