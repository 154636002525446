import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import { campaignDetails, userDonation } from '../state/actions/user.actions';
import useWindowSize from '../utils/useWindowSize';

const CampaignDetails = () => {
    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [amount, setAmount] = useState(0)
    const [modalConfig, setModalConfig] = useState({ display: false, campaignId: '', width: null })

    const { width } = useWindowSize();
    const breakpoint = 620;
    const { campaign } = useSelector(state => state.campaign)
    const { user } = useSelector(state => state.auth)
    const { payment_redirect_url } = useSelector(state => state.donation)

    useEffect(() => {
        if (!user) navigate('/', { replace: true })
        dispatch(campaignDetails({ id: params.id }))
        if (payment_redirect_url) window.location.href = payment_redirect_url
    }, [dispatch, params, payment_redirect_url, navigate, user])


    const donateToCampaign = (campaignId) => {
        if (amount <= 0) return

        const { fullname, email } = user?.user

        dispatch(userDonation({ fullname, email, amount, campaignId, split_code: 'SPL_3XcrK9RKME' }))
        localStorage.setItem('campaignId', JSON.stringify(campaignId))

        setAmount(0)
        setModalConfig({ ...modalConfig, display: false, campaignId: '' })
    }

    return (
        <div>
            {width < breakpoint ?
                <>
                    <Navbar />
                    <MobileWrapper>
                        <DetailImage>
                            <img src={campaign?.campaign?.imageUrl} alt="" />
                        </DetailImage>

                        <div>
                            <p>Campaign Details description: {campaign?.campaign?.description}</p>
                            <div>Funding Goal: <span>#{campaign?.campaign?.fundingGoal}</span></div>
                            <div>Total Amount Raised: <span>#{campaign.totalDonations}</span></div>
                            <div>Location: <span>{campaign?.campaign?.country}</span></div>
                            <div>Expiry Date: <span>{campaign?.campaign?.endDate.split('T')[0]}</span></div>
                        </div>

                        <MobileBtns>
                            {campaign?.campaign?.approved && (
                                <>
                                    <input
                                        type="number"
                                        placeholder="amount"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                        style={{
                                            padding: '10px',
                                            marginBottom: '10px',
                                        }}
                                    />
                                    <button
                                        // onClick={() => setModalConfig({ ...modalConfig, display: true, campaignId: campaign?.campaign?._id, width: '100px' })}
                                        onClick={() => donateToCampaign(campaign?.campaign?._id)}
                                        style={{ cursor: 'pointer' }}
                                    >Donate</button>
                                </>
                            )}
                            <button>
                                <Link to="/fund-someone">Back to Campaigns</Link>
                            </button>
                        </MobileBtns>
                    </MobileWrapper>
                    <Footer />
                </>
                :
                <>
                    <Header />
                    <DesktopWrapper>
                        <DetailImageDesktop>
                            <img src={campaign?.campaign?.imageUrl} alt="" />
                        </DetailImageDesktop>
                        <div>
                            <p>Campaign Details description: {campaign?.campaign?.description}</p>
                            <div>Funding Goal: <span>#{campaign?.campaign?.fundingGoal}</span></div>
                            <div>Total Amount Raised: <span>#{campaign.totalDonations}</span></div>
                            <div>Location: <span>{campaign?.campaign?.country}</span></div>
                            <div>Expiry Date: <span>{campaign?.campaign?.endDate.split('T')[0]}</span></div>
                        </div>

                        <DesktopBtns>
                            <>
                                {campaign?.campaign?.approved && (
                                    <button
                                        onClick={() => setModalConfig({ ...modalConfig, display: true, campaignId: campaign?.campaign?._id })}
                                        style={{ cursor: 'pointer' }}
                                    >Donate</button>
                                )}
                                <button>
                                    <Link to="/fund-someone">Back to Campaigns</Link>
                                </button>
                            </>
                        </DesktopBtns>
                    </DesktopWrapper>

                    {/* MODAL */}
                    {modalConfig.display && (
                        <Modal>
                            <ModalBody width={modalConfig.width}>
                                <div>
                                    <i
                                        className="fa fa-times-circle modal-close"
                                        onClick={() => setModalConfig({ ...modalConfig, display: false, campaignId: campaign?.campaign._id, width: null })}
                                    ></i>
                                    <input
                                        type="number"
                                        placeholder="Enter Amount in NGN"
                                        value={amount}
                                        onChange={(e) => setAmount(e.target.value)}
                                    />
                                    <button onClick={() => donateToCampaign(modalConfig.campaignId)} style={{ cursor: 'pointer' }}>Donate</button>
                                </div>
                            </ModalBody>
                        </Modal>
                    )}
                    <Footer />
                </>
            }
        </div>

    )
}

const MobileWrapper = styled.div`
    padding: 2rem;
`

const DetailImage = styled.div`

    img {
        object-fit: cover;
        width: 100%;
    }
`

const MobileBtns = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem 0;

    button {
        padding: 0.8rem;
        margin-bottom: 1rem;
        background: none;
        border: 1px solid #404295;

        &:nth-child(1) {
            background: #404295;
            color: white;
        }

        a {
            color: #404295;
            text-decoration: none;
        }
    }
`

const DesktopWrapper = styled.div`
    margin: auto;
    max-width: 60%;
    padding: 4rem 0;
`

const DetailImageDesktop = styled.div`
    max-width: 100%;

    img {
        width: 100%
        // object-fit: contain;
    }
`

const DesktopBtns = styled.div`
    display: flex;
    justify-content: space-between;
    margin: 2rem 0;
    width: 50%;

    button {
        padding: 0.8rem;
        color: #404295;
        background: none;
        border: 1px solid #404295;
        border-radius: 4px;

        &:nth-child(1) {
            background: #404295;
            color: white;
        }

        a {
            text-decoration: none;
            color: #404295;
        }
    }
`

const Modal = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    z-index: 999;
    position: fixed;
	top: 0;
	left: 0;
`

const ModalBody = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    color: white;
    background: linear-gradient(to right, #404295, #3c1053);
    justify-content: center;
    width: ${(props) => props.width ? props.width : '450px'};
    height: 200px;
    font-family: 'Nunito';
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
    transition: 0.3s ease;
    div {
        padding: 20px 20px 40px;
        .modal-close {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            font-size: 1.2em;
            cursor: pointer;
        }
        input{
            width: 80%;
            outline: none;
            padding: 15px 20px 25px;
            margin-bottom: 10px;
            font-size: 25px;
            border-radius: 10px;
            background: rgba(0, 0, 0, .5);
            color: white;
            font-weight: 600;
            border: none;
        }
        button {
            color: white;
            padding: 10px;
            background: white;
            width: 40%;
            margin: auto;
            color: #283c86; 
            border-radius: 10px;
            border: none;
            transition: .3s ease;
            font-weight: 600;
            font-size: 20px;
            &:hover{
                transform: translateY(5px);
            }
        }
    }
`

export default CampaignDetails