import React from 'react'
import Slider from 'react-slick';
import styled from 'styled-components';
import useWindowSize from '../utils/useWindowSize';
import AchievementCard from './AchievementCard';
import Achievements from './Achievements';


const PreviousBtn = (props) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
        <PrevBtnStyle className={className} onClick={onClick}>
            <i className="fas fa-chevron-left"></i>
        </PrevBtnStyle>
    );
};

const PreviousBtnMobile = (props) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
        <MobilePrevBtnStyle className={className} onClick={onClick}>
            <i className="fas fa-chevron-left"></i>
        </MobilePrevBtnStyle>
    );
};

const NextBtnMobile = (props) => {
    const { className, onClick } = props;
        return (
            <MobileNextBtnStyle className={className} onClick={onClick}>
                <i className="fas fa-chevron-right" />
            </MobileNextBtnStyle>
        );
};

const NextBtn = (props) => {
const { className, onClick } = props;
    return (
        <NextBtnStyle className={className} onClick={onClick}>
            <i className="fas fa-chevron-right" />
        </NextBtnStyle>
    );
};

const AchievementsCarousel = () => {

    const { width } = useWindowSize();
    const breakpoint = 620;

    return (
        <>
            <Heading>Our Achievements So Far</Heading>
            {width < breakpoint ?  
                <AchievementsCardSection>
                    <Slider className="donor-slider" prevArrow={<PreviousBtnMobile />} nextArrow={<NextBtnMobile />} infinite={true}>
                        <AchievementCard img="./achievement.png" />
                        <AchievementCard img="./donor-1.png" />
                    </Slider>
                </AchievementsCardSection> 
            :
                <AchievementSection>
                    <Slider className="donor-slider" prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} infinite={true} >
                        <Achievements img="./achievement.png" />
                        <Achievements img="./donor-1.png" />
                        <Achievements img="./donor-1.png" />
                    </Slider>
                </AchievementSection>
            }
        </>
    )
}

const Heading = styled.h3`
    text-align: center;
    color: #D9745B;
`

const PrevBtnStyle = styled.button`
    border: none;
    color: #404295;
    font-size: 2rem;
    background-color: white;
    position: absolute;
    top: 250px;
    left: -20px;
`

const MobilePrevBtnStyle = styled.button`
    border: 1px solid #D9745B;
    border-radius: 50%;
    color: white;
    font-size: 1rem;
    background-color: #D9745B;
    position: absolute;
    top: 390px;
    left: -30px;
`

const NextBtnStyle = styled.button`
    border: none;
    color: #404295;
    font-size: 2rem;
    background-color: white;
    position: absolute;
    right: -20px;
    top: 250px;
`

const MobileNextBtnStyle = styled.button`
    border: 1px solid #D9745B;
    border-radius: 50%;
    color: #404295;
    font-size: 1rem;
    background-color: #D9745B;
    position: absolute;
    right: -30px;
    top: 390px;
`

const AchievementSection = styled.section`
    position: relative;
    display: flex;
    justify-content: center;
    margin: 5rem 0;

    .donor-slider {
        width: 80%;
    }

    h3 {
        text-align: center;
        color: #D9745B;
    }

    .slick-prev::before,
    .slick-next::before {
        display: none;
    }

    .slick-arrow.slick-prev {
        z-index: 1;
        color: #404295;
    }
    .slick-arrow.slick-next {
        z-index: 1;
        color: #404295;
    }
`

const AchievementsCardSection = styled.div`
    position: relative;
    margin: 1rem 2rem;

    text-align: center;

    .slick-prev::before,
    .slick-next::before {
        display: none;
    }

    .slick-arrow.slick-prev {
        z-index: 1;
        color: white;
        padding: 1.5rem;
        background-color: #D9745B;

        i {
            position: absolute;
            top: 15px;
            left: 18px;
        }
    }
    .slick-arrow.slick-next {
        z-index: 1;
        color: white;
        padding: 1.5rem;
        background-color: #D9745B;

        i {
            position: absolute;
            top: 15px;
            right: 18px;
        }
    }
`

export default AchievementsCarousel
