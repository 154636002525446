import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { baseUrl } from "../utils/constants"


export const userSignup = createAsyncThunk(
    "user/signup",
    async (userData, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            }
            const { data } = await axios.post(baseUrl + "/api/users/signup", userData, config)
            localStorage.setItem('user', JSON.stringify(data))
            return data
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


export const userSignin = createAsyncThunk(
    "user/signin",
    async (userData, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                }
            }
            const { data } = await axios.post(baseUrl + "/api/users/login", userData, config)
            localStorage.setItem('user', JSON.stringify(data))
            console.log(data)
            return data
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


export const userDonation = createAsyncThunk(
    "user/donation",
    async (_data, { rejectWithValue, getState }) => {
        try {
            const { auth: { user } } = getState()
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user.token,
                    'mode': 'cors'
                }
            }
            const { data } = await axios.post(baseUrl + `/api/payments/${_data.campaignId}`, _data, config)
            // localStorage.setItem('user', JSON.stringify(data))
            // console.log(data)
            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const userVerifyDonation = createAsyncThunk(
    "user/donation-verify",
    async (_data, { rejectWithValue, getState }) => {
        try {
            const { auth: { user } } = getState()
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user.token
                }
            }
            const { data } = await axios.get(baseUrl + `/api/payments/verify/${_data.campaignId}/${_data.ref}`, config)
            // localStorage.setItem('user', JSON.stringify(data))
            // console.log(data)
            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const campaignDetails = createAsyncThunk(
    "user/campaign",
    async ({ id }, { rejectWithValue, getState }) => {
        try {
            const { auth: { user } } = getState()
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user.token
                }
            }
            const { data } = await axios.get(baseUrl + `/api/campaigns/${id}`, config)
            // localStorage.setItem('user', JSON.stringify(data))
            // console.log(data)
            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const userLogout = createAsyncThunk(
    "user/logout",
    async (id = null, { rejectWithValue }) => {
        try {
            localStorage.removeItem('user')
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)
