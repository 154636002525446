import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import useWindowSize from '../utils/useWindowSize';
import { userSignup } from '../state/actions/user.actions';
import Loader from '../components/Loader/Loader';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Register = () => {
    const { width } = useWindowSize();
    const breakpoint = 620;

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({ fullname: '', username: '', email: '', phone: '', password: '', confirm_password: '' })

    const { requestStatus, requestError } = useSelector(state => state.register)
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (requestStatus === 'success') window.location.reload()
        if (user) navigate('/')
    }, [requestStatus, user, navigate])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const onSubmit = (e) => {
        e.preventDefault()

        const { fullname, username, email, phone, password, confirm_password } = formData

        dispatch(userSignup({ fullname, username, email, phone, password, confirm_password }))
    }

    return (
        <div>
            {width < breakpoint ?
                <MobileContainer>
                    <Navbar />

                    <div className="form-container">
                        <h3>Register with Us</h3>
                        {/* <p>Please sign in to continue</p> */}
                        <RegisterForm>
                            <input
                                type="text"
                                placeholder="Full Name"
                                name="fullname"
                                value={formData.fullname}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['fullname'] ? requestError['fullname'] : ""}</small>) : null}
                            <input
                                type="text"
                                placeholder="Username"
                                name="username"
                                value={formData.username}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['username'] ? requestError['username'] : ""}</small>) : null}
                            <input
                                type="text"
                                placeholder="Email Address"
                                name="email"
                                value={formData.email}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['email'] ? requestError['email'] : ""}</small>) : null}
                            <input
                                type="text"
                                placeholder="Phone Number"
                                name="phone"
                                value={formData.phone}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['phone'] ? requestError['phone'] : ""}</small>) : null}
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['password'] ? requestError['password'] : ""}</small>) : null}
                            <input
                                type="password"
                                placeholder="Confirm Password"
                                name="confirm_password"
                                value={formData.confirm_password}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['confirm_password'] ? requestError['confirm_password'] : ""}</small>) : null}

                            <p style={{ fontSize: '0.7rem' }}>By continuing with the registration, you agree to the Cheerbequest Terms and acknowledge receipt of our Privacy Policy</p>

                            <button
                                onClick={onSubmit}
                            >Register</button>
                        </RegisterForm>
                    </div>
                </MobileContainer>
                :
                <DesktopContainer>
                    <Header />
                    <DesktopRegister>
                        <h3>Register with Us</h3>
                        {requestStatus === 'pending' && <Loader />}
                        {requestStatus === "success" ? <small>account created successfully</small> : null}
                        <input
                            type="text"
                            placeholder="Full Name"
                            name="fullname"
                            value={formData.fullname}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['fullname'] ? requestError['fullname'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={formData.username}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['username'] ? requestError['username'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Email Address"
                            name="email"
                            value={formData.email}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['email'] ? requestError['email'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Phone Number"
                            name="phone"
                            value={formData.phone}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['phone'] ? requestError['phone'] : ""}</small>) : null}
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['password'] ? requestError['password'] : ""}</small>) : null}
                        <input
                            type="password"
                            placeholder="Confirm Password"
                            name="confirm_password"
                            value={formData.confirm_password}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['confirm_password'] ? requestError['confirm_password'] : ""}</small>) : null}

                        <p style={{ fontSize: '0.7rem' }}>By continuing with the registration, you agree to the Cheerbequest Terms and acknowledge receipt of our Privacy Policy</p>

                        <button
                            onClick={onSubmit}
                        >Register</button>
                    </DesktopRegister>
                    <Footer />
                </DesktopContainer>
            }
        </div>
    )
}

const DesktopContainer = styled.div`
    max-width: 100%;
`
const DesktopRegister = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0 2rem;
    max-width: 100%;
    width: 50%;

    h3 {
        text-align: center;
        color: #D9745B;
    }

    a {
        text-decoration: none;
        color: black;
        text-align: left;
        margin: 1rem 0;
        font-size: 0.8rem;
    }

    input {
        padding: 1rem;
        margin: 0.5rem 0;
        border: 0.5px solid grey;
        width: 100%;

        &:hover {
            border-color: #404295;
        }

    }

    button {
        width: 30%;
        margin: 0 auto;
        padding: 0.8rem;
        background-color: #404295;
        color: white;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }
`

const MobileContainer = styled.div`
    text-align: center;
    max-width: 619px;

    .form-container {

        margin-top: 3rem;
    }

    h3 {
        color: #D9745B;
    }

`
const RegisterForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    max-width: 100%;

    a {
        text-decoration: none;
        color: black;
        text-align: left;
        margin: 1rem 0;
        font-size: 0.8rem;
    }

    input {
        padding: 1rem;
        margin: 0.5rem 0;
        border: 0.5px solid grey;
        width: 100%;

        &:hover {
            border-color: #404295;
        }

    }

    button {
        width: 100%;
        margin: 0 auto;
        padding: 0.8rem;
        background-color: #404295;
        color: white;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

`

export default Register
