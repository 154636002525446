import styled from "styled-components";

export const Navigation = styled.nav`
    display: flex;
    justify-content: center;
    padding: 0.5rem 2rem;
    background-color: #404295;
    max-width: 100%;
    color: white;
`
