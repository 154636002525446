import styled from "styled-components";

export const CampaignStyles = styled.section`
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;
    margin: 2rem 0;
    margin-bottom: 2rem;

    h4 {
        text-align: center;
        color: #D9745B;
    }
`
