import { configureStore } from '@reduxjs/toolkit';
import campaignReducer from './reducers/campaign.reducers';
import { authSlice, donationSlice, getCampaignSlice, signinSlice, signupSlice, verifyDonationSlice } from './reducers/user.reducers'
import { adminActivitiesSlice, adminApproveCampaignSlice, adminAuthSlice, adminDashboardSlice, adminDonationsSlice, adminGetCampaignSlice, adminSigninSlice, adminSignupSlice, adminWithdrawCampaignSlice } from './reducers/admin.reducers'


const store = configureStore({
    reducer: {
        auth: authSlice.reducer,
        register: signupSlice.reducer,
        login: signinSlice.reducer,
        campaigns: campaignReducer,
        campaign: getCampaignSlice.reducer,
        donation: donationSlice.reducer,
        verifyDonation: verifyDonationSlice.reducer,
        admin_auth: adminAuthSlice.reducer,
        admin_register: adminSignupSlice.reducer,
        admin_login: adminSigninSlice.reducer,
        admin_dashboard: adminDashboardSlice.reducer,
        admin_campaign: adminGetCampaignSlice.reducer,
        admin_approvecampaign: adminApproveCampaignSlice.reducer,
        admin_withdrawcampaign: adminWithdrawCampaignSlice.reducer,
        admin_donations: adminDonationsSlice.reducer,
        admin_activities: adminActivitiesSlice.reducer
    }
});


export default store