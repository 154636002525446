import { createSlice } from "@reduxjs/toolkit";
import { createCampaign, getCampaigns } from "../actions/campaign.actions";


const initialState = {
    campaigns: [],
    requestStatus: "",
    requestError: "",
}

export const campaignSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {},
    extraReducers: {
        [createCampaign.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [createCampaign.fulfilled]: (state, action) => {
            return {
                ...state,
                campaigns: [action.payload, ...state.campaigns],
                requestStatus: "create_campaign_success",
                requestError: ""
            }
        },
        [createCampaign.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        },
        [getCampaigns.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [getCampaigns.fulfilled]: (state, action) => {
            return {
                ...state,
                campaigns: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [getCampaigns.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
});

export default campaignSlice.reducer;