import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { adminLogout } from '../state/actions/admin.actions';

const AdminNav = () => {

    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    return (
        <div>
            <MobileNav style={{background: 'none'}}>
                <LogoContainer>
                    <Link to="/"><img src="/logo.png" alt="cheer bequest logo" /></Link>
                </LogoContainer>
                <div className="burger" ref={node}>
                    <Burger open={open} setOpen={setOpen} />
                    <Menu open={open} setOpen={setOpen} />
                </div>
            </MobileNav>
        </div>
    )
}

const Burger = ({ open, setOpen }) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

const Menu = ({ open }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.admin_auth)

    useEffect(() => {
        if (!user) navigate('/', { replace: true })
    }, [user, navigate])
    const logoutAdmin = (e) => {
        e.preventDefault()
        dispatch(adminLogout())
        window.location.reload()
    }
    return (
        <StyledMenu open={open}>
                <div>
                    <NavUnlistedMobile>
                        <NavLink to="/" className={(navData) => navData.isActive ? "active" : "" }>
                            <img src="/home.png" alt="home" />
                            <li>Home</li>
                        </NavLink>
                        
                        <NavLink to="/admin/dashboard">
                            <img src="/clarity_dashboard-line.png" alt="dashboard" />
                            <li>Dashboard</li>
                        </NavLink>
                        <NavLink to="/admin/campaigns">
                            <img src="/cil_book.png" alt="campaigns" />
                            <li>Campaigns</li>
                        </NavLink>
                        <NavLink to="/admin/donations">
                            <img src="/compass.png" alt="donations" />
                            <li>Donations</li>
                        </NavLink>
                        {/* <NavLink to="/admin/approve-campaigns">
                            <img src="/check-square.png" alt="approved" />
                            <li>Approve Campaign</li>
                        </NavLink>
                        <NavLink to="/admin/withdraw-campaigns">
                            <img src="/shuffle.png" alt="withdrawn" />
                            <li>Withdraw Campaign</li>
                        </NavLink> */}
                        
                        
                        <NavLink to="_" onClick={logoutAdmin}>
                            <img src="/log-out.png" alt="logout" />
                            <li>Logout</li>
                        </NavLink>
                    </NavUnlistedMobile>
                </div>
        </StyledMenu>
    )
}

const MobileNav = styled.nav`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    background-color: #404295;
    max-width: 100%;
    color: white;

    .burger {
        margin: 0.5rem 2rem;
    }
`

const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F4F5FF;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    height: 120vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;
    z-index: 2;

    @media (max-width: 576px) {
        width: 100%;
    }

    a {
        font-size: 2rem;
        text-transform: uppercase;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.5rem;
        color: #0D0C1D;
        text-decoration: none;
        transition: color 0.3s linear;

        @media (max-width: 576px) {
        font-size: 1.5rem;
        text-align: center;
        }

        &:hover {
        color: #343078;
        }
    }
`

const StyledBurger = styled.button`
    position: absolute;
    // top: 5%;
    // left: 34rem;
    // margin: 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;


    &:focus {
        outline: none;
    }
    // #0D0C1D #EFFFFA
    div {
        width: 2rem;
        height: 0.25rem;
        background: ${({ open }) => open ? '#404295' : '#404295'};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

const NavUnlistedMobile = styled.ul`
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;

    a {
        display: flex;
        align-items: center;

        img {
            width: 1.5rem;
            height: 1.5rem;
            margin: 0 1rem;

        }
    }

    li {
        font-size: 0.8rem;
        list-style: none;
    }
`

const LogoContainer = styled.div`
    width: 3rem;
    height: 3rem;
    z-index: 10;
    // position: absolute;

    img {
        width: 100%;
        height: 100%;
    }
`

export default AdminNav
