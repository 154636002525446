import styled from "styled-components";

export const Hero = styled.header`
    position: relative;
    height: 80vh;
    background: url('./hero-image.png');
    background-color: #F4F5FF;
    background-repeat: no-repeat;
    background-position: right center;
    display: flex;
    justify-content: space-between;
    padding: 0 2rem;
    color: #D9745B;
    
    div {
        margin: auto 0;
        width: 40%;
        word-wrap: break-word;

        p {
            color: black;
        }

        div {
            display: flex;
            justify-content: space-between;
        }

    }
`
