import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { userLogout } from '../state/actions/user.actions'
import useWindowSize from '../utils/useWindowSize'
import Navbar from './Navbar'
import { Hero } from './styles/Hero.styled'

const Header = () => {

    const { width } = useWindowSize();
    const breakpoint = 620;

    const dispatch = useDispatch()
    const { user, requestStatus } = useSelector(state => state.auth)

    useEffect(() => {
        if (requestStatus === 'success') window.location.reload()
    }, [requestStatus])

    return (
        <>
            <Navbar />
            {/* <Hero> */}
            <div>
                {width <= breakpoint ?
                    <>
                        <MobileHeader>
                            <div>
                                <h1>Cheer Bequest</h1>
                            </div>

                            <div className="hero-image">

                            </div>

                            <div>
                                <p>The crowdfunding that got you covered. A few easy steps to get you started and have access to funds as well as donating funds for urgents needs.</p>

                                {user ? (
                                    <div className="buttons" style={{ margin: "2rem 0", display: "flex", justifyContent: "center" }}>
                                        <Logout
                                            onClick={() => {
                                                dispatch(userLogout())
                                            }}
                                        >Logout</Logout>
                                    </div>
                                ) : (
                                    <div className="buttons" style={{ margin: "2rem 0", display: "flex", justifyContent: "center" }}>
                                        <Link to="/register"><SignUp style={{ marginRight: "1rem" }}>Sign Up</SignUp></Link>
                                        <Link to="/login"><SignIn>Sign In</SignIn></Link>
                                    </div>
                                )}
                            </div>
                        </MobileHeader>
                    </>
                    :
                    <Hero>
                        <Logo style={{ width: '4rem', height: '4rem' }}>
                            <img src="/logo.png" alt="cheer bequest logo" />
                        </Logo>
                        <div>
                            <h1>Cheer Bequest</h1>
                            <p>The crowdfunding that got you covered. A few easy steps to get you started and have access to funds as well as donating funds for urgents needs.</p>

                            {user ? (
                                <Logout
                                    onClick={() => {
                                        dispatch(userLogout())
                                    }}
                                >Logout</Logout>
                            ) : (
                                <div>
                                    <Link to="/register"><SignUp>Sign Up</SignUp></Link>
                                    <Link to="/login"><SignIn>Sign In</SignIn></Link>
                                </div>
                            )}
                        </div>


                    </Hero>
                }

                {/* <div>
                        <SignUp>Sign Up</SignUp>
                        <SignIn>Sign In</SignIn>
                    </div> */}
            </div>
            {/* </Hero> */}
        </>
    )
}

const MobileHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 80vh;
    background-color: #F4F5FF;
    // background-image: url('./hero-image.png');
    

    .hero-image {
        width: 80%;
        height: 50%;
        background-image: url('./hero-image.png');
        background-position: center center;
        background-size: 80%;
        background-repeat: no-repeat;
    }

    .buttons {
        display: none;
    }

    h1 {
        text-align: center;
        color: #D9745B;
    }
    p {
        text-align: center;
        margin: 0 2rem;
    }
`

const SignUp = styled.button`
    padding: 0.8rem 1.5rem;
    border: 1px solid #404295;
    border-radius: 0.2rem;
    background-color: #404295;
    color: white;

    &:hover {
        cursor: pointer;
        background-color: white;
        border: 1px solid #404295;
        color: #404295;
    }
`
const SignIn = styled.button`
    padding: 0.8rem 1.5rem;
    border: 1px solid #404295;
    border-radius: 0.2rem;
    background-color: white;
    color: #404295;

    &:hover {
        cursor: pointer;
        background-color: #404295;
        border: none;
        color: white;
    }
`

const Logout = styled.button`
    padding: 0.8rem 1.5rem;
    border: 1px solid #404295;
    border-radius: 0.2rem;
    background-color: white;
    color: #404295;

    &:hover {
        cursor: pointer;
        background-color: #404295;
        border: none;
        color: white;
    }
`

const Logo = styled.div`
    position: absolute;
    top: 5px;
    width: 3rem;
    height: 3rem;

    img {
        width: 100%;
        height: 100%;
    }
`

export default Header
