import React, { useEffect } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import styled from 'styled-components'
// import DonorCarousel from '../components/DonorCarousel'
import CampaignCarousel from '../components/CampaignCarousel'
import useWindowSize from '../utils/useWindowSize'
import CampaignSection from '../components/CampaignSection'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaigns } from '../state/actions/campaign.actions'

const Home = () => {

    const { width } = useWindowSize();
    const breakpoint = 620;

    const dispatch = useDispatch()
    const { campaigns } = useSelector(state => state.campaigns)

    useEffect(() => {
        dispatch(getCampaigns())
    }, [dispatch])

    return (
        <>
            <Header />
            <UserSteps>
                <div>
                    <h3>How it Works</h3>
                </div>
                {width < breakpoint ?
                    <div className="mobile-steps">
                        <div>
                            <span>
                                <img src="./create-account.png" alt="create account" />
                            </span>
                            <h4>Create an Account</h4>
                            <p>
                                Share your campaign page with friends,
                                family and everyone via any social
                                media platform and also via
                                phone calls and emails.
                            </p>
                        </div>
                        <div>
                            <span>
                                <img src="./share-campaign.png" alt="share campaign" />
                            </span>
                            <h4>Share your Campaign</h4>
                            <p>
                                Share your campaign page with friends,
                                family and everyone via any social
                                media platform and also via
                                phone calls and emails.
                            </p>
                        </div>
                        <div>
                            <span>
                                <img src="./contributions.png" alt="contributions" />
                            </span>
                            <h4>Retrieve Contributions</h4>
                            <p>
                                Start accepting contributions and
                                donations from donors  all
                                over the world and accessing
                                the funds made.
                            </p>
                        </div>
                    </div>
                    :
                    <div className="steps">
                        <div>
                            <span>
                                <img src="./create-account.png" alt="create account" />
                            </span>
                            <h4>Create an Account</h4>
                            <p>
                                Share your campaign page with friends,
                                family and everyone via any social
                                media platform and also via
                                phone calls and emails.
                            </p>
                        </div>
                        <div>
                            <span>
                                <img src="./share-campaign.png" alt="share campaign" />
                            </span>
                            <h4>Share your Campaign</h4>
                            <p>
                                Share your campaign page with friends,
                                family and everyone via any social
                                media platform and also via
                                phone calls and emails.
                            </p>
                        </div>
                        <div>
                            <span>
                                <img src="./contributions.png" alt="contributions" />
                            </span>
                            <h4>Retrieve Contributions</h4>
                            <p>
                                Start accepting contributions and
                                donations from donors  all
                                over the world and accessing
                                the funds made.
                            </p>
                        </div>
                    </div>
                }

                {/* <div className="campaign-button">
                    <button>Create Campaign</button>
                </div> */}
            </UserSteps>
            {width < breakpoint ?
                <CampaignSection campaigns={campaigns}/>
                :
                <CampaignCarousel campaigns={campaigns}/>
            }
            {/* <Donors /> */}
            {/* <DonorCarousel /> */}

            {/* {width < breakpoint ? <div></div> :
                <></>
            } */}
            <Footer />
        </>
    )
}

const UserSteps = styled.section`
    display: flex;
    flex-direction: column;
    padding: 2rem 2rem;
    text-align: center;
    justify-content: space-between;

    h3 {
        color: #D9745B;
        margin-bottom: 1rem;
    }
    
    .mobile-steps {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        h4 {
            color: #D9745B;
        }
    }

    .steps {
        display: flex;
        justify-content: space-between;
        width: 100%;

        div {
            width: 30%;
        }

        h4 {
            color: #D9745B;
        }
    }

    .campaign-button {
        button {
            padding: 1rem 2.5rem;
            background-color: #404295;
            border: none;
            color: white;
            margin: 2rem 0;
        }
    }
`

export default Home
