import React from 'react'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import useWindowSize from '../utils/useWindowSize'

const HowItWorks = () => {

    const { width } = useWindowSize()
    const breakpoint = 620;

    return (
        <>
        {width < breakpoint ? <Navbar /> : <Header />}
        
        {width < breakpoint ? 
            <WorksSection>
                <h2>How it works</h2>
                <WorkStepsMobile>
                    <StepContainer>
                        <div>
                            <img src="./step-1.png" alt="how it works" />
                        </div>
                        <StepInfoMobile>
                            <div>
                                <span style={{width: '20%', margin: '0 auto'}}>
                                    <img src="./create-account.png" alt="create account" />
                                </span>
                                <h4>Create and Launch Campaign</h4>
                            </div>
                            <p>
                                Register and create a cheerbequest account. Then Proceed
                                to launch your campaign page witth the full details
                                of your campaign. Just a step to launching your  campaign
                            </p>
                        </StepInfoMobile>
                    </StepContainer>
                </WorkStepsMobile>
                <WorkStepsMobile>
                    <StepContainer>
                        <div>
                            <img src="./step-2.png" alt="how it works" />
                        </div>
                        <StepInfoMobile>
                            <div>
                                <span style={{width: '20%', margin: '0 auto'}}>
                                    <img src="./share-campaign.png" alt="create account" />
                                </span>
                                <h4>Share Your Campaign</h4>
                            </div>
                            <p>
                                Share Your campaign page with friends, family and everyone via every social media platforms including 
                                phone calls and emails
                            </p>
                        </StepInfoMobile>
                    </StepContainer>
                </WorkStepsMobile>
                <WorkStepsMobile>
                    <StepContainer>
                        <div>
                            <img src="./step-3.png" alt="how it works" />
                        </div>
                        <StepInfoMobile>
                            <div>
                                <span style={{width: '20%', margin: '0 auto'}}>
                                    <img src="./contributions.png" alt="create account" />
                                </span>
                                <h4>Receive Contributions</h4>
                            </div>
                            <p>
                                Start accepting contributions and donations from donors 
                                all over the country and accessing the funds contrbuted.
                            </p>
                        </StepInfoMobile>
                    </StepContainer>
                </WorkStepsMobile>
            </WorksSection> 
        
        : 
            <>
                
                <WorksSection>
                    {/* <div> */}
                        <h2>How it works</h2>
                    {/* </div> */}
                    <WorkSteps>
                        <div>
                            <img src="./step-1.png" alt="how it works" />
                        </div>
                        <StepInfo>
                            <div>
                                <span>
                                    <img src="./create-account.png" alt="create account" />
                                </span>
                                <h4>Create and Launch Campaign</h4>
                            </div>
                            <p>
                                Register and create a cheerbequest account. Then Proceed
                                to launch your campaign page witth the full details
                                of your campaign. Just a step to launching your  campaign
                            </p>
                        </StepInfo>
                    </WorkSteps>
                    <WorkSteps>
                        <div>
                            <img src="./step-2.png" alt="how it works" />
                        </div>
                        <StepInfo>
                            <div>
                                <span>
                                    <img src="./share-campaign.png" alt="create account" />
                                </span>
                                <h4>Share Your Campaign</h4>
                            </div>
                            <p>
                                Share Your campaign page with friends, family and everyone via every social media platforms including 
                                phone calls and emails
                            </p>
                        </StepInfo>
                    </WorkSteps>
                    <WorkSteps>
                        <div>
                            <img src="./step-3.png" alt="how it works" />
                        </div>
                        <StepInfo>
                            <div>
                                <span>
                                    <img src="./contributions.png" alt="create account" />
                                </span>
                                <h4>Receive Contributions</h4>
                            </div>
                            <p>
                                Start accepting contributions and donations from donors 
                                all over the country and accessing the funds contrbuted.
                            </p>
                        </StepInfo>
                    </WorkSteps>
                </WorksSection>
            </>
            }
            <Footer />
        </>
    )
}

const WorksSection = styled.section`
    padding: 1rem 2rem;

    h2 {
        color: #D9745B;
        text-align: center;
    }
`

const WorkSteps = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2.5rem 0;
`
const WorkStepsMobile = styled.div`
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: 2rem 0;
`

const StepContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 2rem;
    text-align: center;

    img {
        width: 100%;
    }
    h4 {
        color: #D9745B;
    }
`

const StepInfo = styled.div`
    width: 40%;
    word-wrap: break-word;

    div {
        display: flex;

        span {
            margin-right: 1rem;
        }

        h4 {
            color: #D9745B;
        }
    }
`
const StepInfoMobile = styled.div`
    width: 100%;
    word-wrap: break-word;

    div {
        display: flex;
        flex-direction: column;
    }
`

export default HowItWorks
