import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import { baseUrl } from "../utils/constants"


export const createCampaign = createAsyncThunk(
    'campaigns/create',
    async (campaign, { rejectWithValue, getState }) => {
        try {
            const { auth: { user } } = getState()
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user.token
                }
            }
            const formdata = new FormData()
            formdata.append("file", campaign.image)
            formdata.append("upload_preset", "tisanyada")

            const upload = await axios.post("https://api.cloudinary.com/v1_1/tisanyada/image/upload", formdata)
            const { data } = await axios.post(baseUrl + "/api/campaigns", { ...campaign, imageUrl: upload.data.secure_url }, config)
            return data
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getCampaigns = createAsyncThunk(
    'campaigns/fetch',
    async (_id = null, { rejectWithValue, getState }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            }
            const { data } = await axios.get(baseUrl + "/api/campaigns", config)
            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)