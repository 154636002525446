import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import useWindowSize from '../utils/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { createCampaign } from '../state/actions/campaign.actions'
import Loader from '../components/Loader/Loader'

const CreateCampaign = () => {
    const { width } = useWindowSize();
    const breakpoint = 620;

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({ title: '', category: '', description: '', videoUrl: '', fundingGoal: '', startDate: '', endDate: '', country: '' })
    const [image, setImage] = useState({})

    const { requestStatus, requestError } = useSelector(state => state.campaigns)
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (!user) navigate('/')
        if(requestStatus === 'create_campaign_success') navigate("/")
    }, [user, navigate, requestStatus])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const onSubmit = async (e) => {
        e.preventDefault()
        
        if(!image) return

        const { title, category, description, videoUrl, fundingGoal, startDate, endDate, country } = formData
        dispatch(createCampaign({ title, category, description, image, videoUrl, fundingGoal, startDate, endDate, country }))
    }

    return (
        <div>
            {width < breakpoint ?
                <>
                    <Navbar />
                    <Heading>Create Campaign</Heading>
                    <MobileCampaignForm>
                        {requestError === 'Unauthorized' ? <small>Please login to access this resource</small> : null}
                        {requestStatus === "success" ? <small>Campaign Created</small> : null}
                        <input
                            type="text"
                            placeholder="Title"
                            name="title"
                            value={formData.title}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['title'] ? requestError['title'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Category"
                            name="category"
                            value={formData.category}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['category'] ? requestError['category'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Description"
                            name="description"
                            value={formData.description}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['description'] ? requestError['description'] : ""}</small>) : null}
                        <input
                            type="file"
                            placeholder="Feature Image URL"
                            name="image"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                        {requestError ? (<small>{requestError['image'] ? requestError['image'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Feature Video URL"
                            name="videoUrl"
                            value={formData.videoUrl}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['videoUrl'] ? requestError['videoUrl'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Funding Goal"
                            name="fundingGoal"
                            value={formData.fundingGoal}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['fundingGoal'] ? requestError['fundingGoal'] : ""}</small>) : null}
                        <label htmlFor="files" style={{ marginLeft: '0.5rem' }}>Start Date</label>
                        <input
                            type="date"
                            name="startDate"
                            value={formData.startDate}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['startDate'] ? requestError['startDate'] : ""}</small>) : null}
                        <label htmlFor="files" style={{ marginLeft: '0.5rem' }}>End Date</label>
                        <input
                            type="date"
                            name="endDate"
                            value={formData.endDate}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['endDate'] ? requestError['endDate'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Campaign Country"
                            name="country"
                            value={formData.country}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['country'] ? requestError['country'] : ""}</small>) : null}
                        <button
                            onClick={onSubmit}
                        >Submit Campaign</button>
                    </MobileCampaignForm>
                </>
                :
                <>
                    <Header />
                    <Heading>
                        Create Campaign <br />
                    </Heading>
                    <CampaignForm>
                        {requestError === 'Unauthorized' ? <small>Please login to access this resource</small> : null}
                        {requestStatus === "pending" && <Loader />}
                        {requestStatus === "success" ? <small>Campaign Created</small> : null}
                        <input
                            type="text"
                            placeholder="Title"
                            name="title"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['title'] ? requestError['title'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Category"
                            name="category"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['category'] ? requestError['category'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Description"
                            name="description"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['description'] ? requestError['description'] : ""}</small>) : null}
                        <input
                            type="file"
                            placeholder="Feature Image URL"
                            name="image"
                            onChange={(e) => setImage(e.target.files[0])}
                        />
                        {requestError ? (<small>{requestError['image'] ? requestError['image'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Feature Video URL"
                            name="videoUrl"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['videoUrl'] ? requestError['videoUrl'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Funding Goal"
                            name="fundingGoal"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['fundingGoal'] ? requestError['fundingGoal'] : ""}</small>) : null}
                        <label
                            htmlFor="files"
                            style={{ marginLeft: '0.5rem' }}>Start Date</label>
                        <input
                            type="date"
                            name="startDate"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['startDate'] ? requestError['startDate'] : ""}</small>) : null}
                        <label
                            htmlFor="files"
                            style={{ marginLeft: '0.5rem' }}>End Date</label>
                        <input
                            type="date"
                            name="endDate"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['endDate'] ? requestError['endDate'] : ""}</small>) : null}
                        <input
                            type="text"
                            placeholder="Campaign Country"
                            name="country"
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['country'] ? requestError['country'] : ""}</small>) : null}
                        <div>
                            <button
                                onClick={onSubmit}
                            >Submit Campaign</button>
                            <button>Cancel</button>
                        </div>
                    </CampaignForm>
                </>
            }
            <Footer />
        </div>
    )
}

const Heading = styled.h3`
    margin: 2rem 0;
    color: #D9745B;
    text-align: center;
`

const MobileCampaignForm = styled.form`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2rem auto;
    padding: 2rem;

    input, button {
        margin: 0.5rem;
        padding: 1rem;
    }
    button {
        background-color: #404295;
        color: white;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }
`
const CampaignForm = styled.div`
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 2rem auto;
    padding: 2rem;

    input {
        margin: 0.5rem;
        padding: 1rem;
        border: 1px solid grey;

        &:hover {
            border: 1px solid #404295;
        }
    }

    div {
        display: flex;
        justify-content: space-between;
    }
    button:nth-child(1) {
        background-color: #404295;
        border: none;
        color: white;
    }

    button {
        margin: 0.5rem;
        padding: 0.5rem;
    }

    small {
        display: flex;
        color: rgb(203, 112, 71);
    }
`

export default CreateCampaign
