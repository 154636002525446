import { createAsyncThunk } from "@reduxjs/toolkit"
import axios from "axios"
import { baseUrl } from "../utils/constants"


export const adminSignup = createAsyncThunk(
    "admin/signup",
    async (adminData, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            }
            const { data } = await axios.post(baseUrl + "/api/admin/signup", adminData, config)
            localStorage.setItem('admin', JSON.stringify(data))
            return data
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


export const adminSignin = createAsyncThunk(
    "admin/signin",
    async (adminData, { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                }
            }
            const { data } = await axios.post(baseUrl + "/api/admin/login", adminData, config)
            localStorage.setItem('admin', JSON.stringify(data))
            console.log(data)
            return data
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getAdminDashboard = createAsyncThunk(
    "admin/dashboard",
    async (id = null, { rejectWithValue, getState }) => {
        try {
            const { admin_auth: { user } } = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user
                }
            }
            const { data } = await axios.get(baseUrl + "/api/admin/dashboard", config)
            // console.log(data);
            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getCampaign = createAsyncThunk(
    "admin/campaign",
    async (id, { rejectWithValue, getState }) => {
        try {
            const { admin_auth: { user } } = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user
                }
            }
            const { data } = await axios.get(baseUrl + `/api/admin/get-campaign/${id}`, config)
            // console.log(data);
            return data
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


export const approveCampaign = createAsyncThunk(
    "admin/campaign/approve",
    async (id, { rejectWithValue, getState }) => {
        try {
            const { admin_auth: { user } } = getState();
            const headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Authorization': user
            }
            const req = await fetch(baseUrl + `/api/admin/approve-campaign/${id}`, {
                method: 'POST',
                headers
            })
            const data = await req.json()

            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const withdrawCampaign = createAsyncThunk(
    "admin/campaign/withdraw",
    async (id, { rejectWithValue, getState }) => {
        try {
            const { admin_auth: { user } } = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user
                }
            }
            const { data } = await axios.delete(baseUrl + `/api/admin/withdraw-campaign/${id}`, config)
            // console.log(data);
            return data
        } catch (error) {
            // console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getDonations = createAsyncThunk(
    "donations",
    async (id=null, { rejectWithValue, getState }) => {
        try {
            // const { admin_auth: { user } } = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // 'Authorization': user
                }
            }
            const { data } = await axios.get(baseUrl + `/api/all/donations`, config)
            // console.log(data);
            return data
        } catch (error) {
            console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const getActivities = createAsyncThunk(
    "admin/activities",
    async (id=null, { rejectWithValue, getState }) => {
        try {
            const { admin_auth: { user } } = getState();
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    'Authorization': user
                }
            }
            const { data } = await axios.get(baseUrl + `/api/admin/activities`, config)
            // console.log(data);
            return data
        } catch (error) {
            console.log(error.response);
            return rejectWithValue(error.response.data)
        }
    }
)


export const adminLogout = createAsyncThunk(
    "admin/logout",
    async (id = null, { rejectWithValue }) => {
        try {
            localStorage.removeItem('admin')
        } catch (error) {
            // console.log(error.response.data);
            return rejectWithValue(error.response.data)
        }
    }
)


