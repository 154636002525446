import React from 'react'
import styled from 'styled-components'

const Achievements = ({img}) => {
    return (
        <>
            <AchievementStyles>
                <div className="achievement-info">
                    
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet egestas vulputate iaculis turpis et nibh malesuada. Neque fermentum mattis auctor mi. Non eget nec, nam risus purus libero, vulputate. Quis eget hac maecenas quisque amet, fringilla. Euismod enim venenatis sollicitudin laoreet tristique. Volutpat commodo pretium nibh id rutrum. Eu consequat diam ipsum facilisis. Risus ultricies eget facilisis vel. Egestas sodales purus odio ullamcorper aenean consequat turpis massa. Aliquam magna suspendisse dapibus nullam convallis.
                        Vitae at aliquam nibh nulla luctus egestas placerat vestibulum. Vulputate malesuada pharetra et malesuada tellus in malesuada mattis. Tempor, dui, ipsum vel dis feugiat in amet ipsum leo. Amet vel volutpat, ac non volutpat nisl. Leo velit sed ultricies eu porta mauris, amet. At adipiscing augue integer sed iaculis habitant eget sit. Lacus at cras duis sed nam pharetra amet.
                    </p>
                    
                </div>
                <AchievementImage>
                    <img src={img} alt="achievement" />
                </AchievementImage>
            </AchievementStyles>
        </>
    )
}

const AchievementStyles = styled.div`
    display: flex;
    justify-content: center;
    padding: 1rem 2rem;
    align-items: center;
    width: 100%;

    .achievement-info {
        margin: 0 2rem;
        width: 70%;
    }
`
const AchievementImage = styled.div`
    width: 50%;

    img {
        width: 100%;
    }
`

export default Achievements
