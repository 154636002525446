import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import AdminNav from '../components/AdminNav'
import Footer from '../components/Footer'
import NavAside from '../components/NavAside'
import { getCampaign, approveCampaign } from '../state/actions/admin.actions'
import useWindowSize from '../utils/useWindowSize'

const ApproveCampaign = () => {

    const { width } = useWindowSize()
    const breakpoint = 620;

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { campaign } = useSelector(state => state.admin_campaign)
    const { requestStatus } = useSelector(state => state.admin_approvecampaign)

    useEffect(() => {
        dispatch(getCampaign(params.id))
        if (requestStatus === 'success') navigate('/admin/campaigns', { replace: true })
    }, [dispatch, params, requestStatus, navigate])

    const onSubmit = (e) => {
        e.preventDefault()

        dispatch(approveCampaign(params.id))
    }

    return (
        <div>
            {width < breakpoint ?
                <MobileContainer>
                    <AdminNav />
                    <Date>
                        <h5>Monday, 7th December 2021</h5>
                    </Date>

                    <main>
                        <h3>Approve/Launch Campaign</h3>
                        <CampaignForm>
                            <input
                                type="text"
                                placeholder="Title"
                                value={campaign && campaign.title}
                                disabled
                            />
                            <input
                                type="text"
                                placeholder="Category"
                                value={campaign && campaign.category}
                                disabled
                            />
                            <textarea
                                type="text"
                                placeholder="Description"
                                rows="5"
                                value={campaign && campaign.description}
                                disabled
                            ></textarea>
                            <input
                                type="text"
                                placeholder="Feature Image URL"
                                value={campaign && campaign.imageUrl}
                                disabled
                            />
                            <input
                                type="text"
                                placeholder="Feature Video URL"
                                value={campaign && campaign.videoUrl}
                                disabled
                            />
                            <input
                                type="text"
                                placeholder="Funding Goal"
                                value={campaign && campaign.fundingGoal}
                                disabled
                            />
                            <label htmlFor="start">Start Date</label>
                            <input
                                type="text"
                                name="startDate"
                                value={campaign && campaign.startDate}
                                disabled
                            />
                            <label htmlFor="end">End Date</label>
                            <input
                                type="text"
                                name="endDate"
                                placeholder="End Date"
                                value={campaign && campaign.endDate}
                                disabled
                            />
                            <input
                                type="text"
                                name="country"
                                placeholder="Country"
                                value={campaign && campaign.country}
                                disabled
                            />
                            <div>
                                <input type="radio" />
                                <label>Make contributors anonymous on the contributors table</label>
                            </div>
                            <button
                                type="submit"
                                style={{ cursor: 'pointer' }}
                                onClick={onSubmit}
                            >Approve Campaign</button>
                            <button className="cancel">Cancel</button>
                        </CampaignForm>

                        <Footer />
                    </main>
                </MobileContainer>
                :
                <DesktopContainer>
                    <main>
                        <NavAside />
                        <MainSection>
                            <DesktopInfo>
                                {/* <div>
                                <h4>Hello, Michael!</h4>
                                <p>
                                    20% of each donations funding your campaigns will be deducted for the support of the platform
                                </p>
                            </div> */}
                                <div>
                                    <Date>
                                        <h5>Monday, 7th December 2021</h5>
                                    </Date>
                                </div>
                            </DesktopInfo>

                            <h3>Approve/Launch Campaign</h3>
                            <CampaignFormDesktop>
                                <input
                                    type="text"
                                    placeholder="Title"
                                    value={campaign && campaign.title}
                                    disabled
                                />
                                <input
                                    type="text"
                                    placeholder="Category"
                                    value={campaign && campaign.category}
                                    disabled
                                />
                                <textarea
                                    type="text"
                                    placeholder="Description"
                                    rows="5"
                                    value={campaign && campaign.description}
                                    disabled
                                ></textarea>
                                <input
                                    type="text"
                                    placeholder="Feature Image URL"
                                    value={campaign && campaign.imageUrl}
                                    disabled
                                />
                                <input
                                    type="text"
                                    placeholder="Feature Video URL"
                                    value={campaign && campaign.videoUrl}
                                    disabled
                                />
                                <input
                                    type="text"
                                    placeholder="Funding Goal"
                                    value={campaign && campaign.fundingGoal}
                                    disabled
                                />
                                <label htmlFor="start">Start Date</label>
                                <input
                                    type="text"
                                    name="startDate"
                                    value={campaign && campaign.startDate}
                                    disabled
                                />
                                <label htmlFor="end">End Date</label>
                                <input
                                    type="text"
                                    name="endDate"
                                    placeholder="End Date"
                                    value={campaign && campaign.endDate}
                                    disabled
                                />
                                <input
                                    type="text"
                                    name="country"
                                    placeholder="Country"
                                    value={campaign && campaign.country}
                                    disabled
                                />
                                {/* <div>
                                    <input type="radio" name="radio" />
                                    <label htmlFor="radio">Make contributors anonymous on the contributors table</label>
                                </div> */}

                                <div className="buttons">
                                    <button
                                        type="submit"
                                        style={{ cursor: 'pointer' }}
                                        onClick={onSubmit}
                                    >Approve Campaign</button>
                                    <button className="cancel">Cancel</button>
                                </div>
                            </CampaignFormDesktop>
                        </MainSection>
                    </main>
                    <Footer />
                </DesktopContainer>
            }
        </div>
    )
}

const MobileContainer = styled.div`
    max-width: 100%;

    main {
        h3 {
            text-align: center;
            color: #D9745B;
        }
    }
`

const Date = styled.div`
    text-align: center;
    h5 {
        color: #D9745B;
    }
`



const CampaignForm = styled.form`
    display: flex;
    flex-direction: column;
    margin: 0 2rem;

    select {
        padding: 1rem;
    }

    input {
        margin: 0.5rem 0;
        padding: 1rem;
        outline: none;

        &:focus {
            border: 1px solid #404295;
        }
    }

    button {
        padding: 0.8rem;
        background-color: #404295;
        color: white;
        border: none;
        margin: 0.5rem 0;

    }
    .cancel {
        padding: 0.8rem;
        background-color: white;
        color: #404295;
        border: 2px solid #404295;
    }

    // @media(min-width: 621px) {
        
    // }
`

const CampaignFormDesktop = styled.form`
    display: flex;
    flex-direction: column;

    width: 100%;
    margin: 0 auto;

    select {
        padding: 1rem;
    }

    input {
        margin: 0.5rem 0;
        padding: 1rem;
        outline: none;

        &:focus {
            border: 1px solid #404295;
        }
    }

    button {
        padding: 0.8rem;
        background-color: #404295;
        color: white;
        border: none;
        margin: 0.5rem 0;

    }
    .cancel {
        padding: 0.8rem;
        background-color: white;
        color: #404295;
        border: 2px solid #404295;
    }

        .buttons {
            display: flex;
            justify-content: space-between;
            align-content: center;
            margin-bottom: 1rem;
        }
`

const DesktopContainer = styled.div`
    main {
        display: flex;
    }
`
const DesktopInfo = styled.div`
    display: flex;
    justify-content: space-between;
`

const MainSection = styled.section`
    margin-right: 2rem;

    h3 {
        text-align: center;
        color: #D9745B;
    }
`


export default ApproveCampaign
