import React from 'react'
import styled from 'styled-components'


const CampaignSection = ({ campaigns }) => {
    return (
        <>
            <Campaigns>
                <h3>Campaigns</h3>
                <CampaignCards>
                    {campaigns.length > 0 ? campaigns.map(campaign => (
                        <div key={campaign._id}>
                            <CardImage>
                                <img src={campaign.imageUrl} alt="campaign" />
                            </CardImage>
                            <div>
                                <p>{campaign.description}</p>

                                <p>#{campaign.fundingGoal}</p>
                                {/* <Meter>
                                    <div></div>
                                </Meter> */}
                                {/* <MeterProgress>27% Progress</MeterProgress> */}
                            </div>
                        </div>
                    )) : (
                        <>
                            <div>
                                <CardImage>
                                    <img src="./old-man.png" alt="campaign" />
                                </CardImage>
                                <div>
                                    <p>Help Johnny Green get rid of a hole in the ...</p>

                                    <p>$10,000 Raised</p>
                                    <Meter>
                                        <div></div>
                                    </Meter>
                                    <MeterProgress>27% Progress</MeterProgress>
                                </div>
                            </div>
                            <div>
                                <CardImage>
                                    <img src="./baby-picture.png" alt="campaign" />
                                </CardImage>
                                <div>
                                    <p>Help Johnny Green get rid of a hole in the ...</p>

                                    <p>$10,000 Raised</p>
                                    <Meter>
                                        <div></div>
                                    </Meter>
                                    <MeterProgress>27% Progress</MeterProgress>
                                </div>
                            </div>
                        </>
                    )}
                    {/* <div>
                    <CardImage>
                        <img src="./old-man.png" alt="campaign" />
                    </CardImage>
                    <div>
                        <p>Help Johnny Green get rid of a hole in the ...</p>

                        <p>$10,000 Raised</p>
                        <Meter>
                            <div></div>
                        </Meter>
                        <MeterProgress>27% Progress</MeterProgress>
                    </div>
                </div>
                <div>
                    <CardImage>
                        <img src="./baby-picture.png" alt="campaign" />
                    </CardImage>
                    <div>
                        <p>Help Johnny Green get rid of a hole in the ...</p>

                        <p>$10,000 Raised</p>
                        <Meter>
                            <div></div>
                        </Meter>
                        <MeterProgress>27% Progress</MeterProgress>
                    </div>
                </div>
                <div>
                    <CardImage>
                        <img src="./baby-picture.png" alt="campaign" />
                    </CardImage>
                    <div>
                        <p>Help Johnny Green get rid of a hole in the ...</p>

                        <p>$10,000 Raised</p>
                        <Meter>
                            <div></div>
                        </Meter>
                        <MeterProgress>27% Progress</MeterProgress>
                    </div>
                </div> */}
                </CampaignCards>
            </Campaigns>
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                <MoreInfo>See More</MoreInfo>
            </div> */}
        </>
    )
}

const Campaigns = styled.div`
    // max-width: 375px;

    h3 {
        text-align: center;
        color: #D9745B;
    }
`

const CardImage = styled.div`
    width: 100%;
    

    img {
        width: 100%;
        border-radius: 10px;
    }
`

const CampaignCards = styled.section`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    margin: 0 auto;
    padding: 2rem 1rem;
`

const MoreInfo = styled.button`
    margin: 0 auto;
    background-color: #404295;
    color: white;
    padding: 0.8rem;
    border: none;
`

const Meter = styled.div`
    background-color: #FCEDE5;
    width: 80%;
    padding: 0.2rem;
    border-radius: 15px;

    div {
        background-color: #D9745B;
        color: white;
        padding: 2%;
        width: 60%;
        // text-align: right;
        // font-size: 20px;
        border-radius: 15px;
    }
`
const MeterProgress = styled.div`
    color: #D9745B;
    font-size: 0.8rem;
`

export default CampaignSection
