import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import useWindowSize from '../utils/useWindowSize';
import { userSignin } from '../state/actions/user.actions';
import Loader from '../components/Loader/Loader';
import { adminSignin } from '../state/actions/admin.actions';

const Login = () => {
    const { width } = useWindowSize();
    const breakpoint = 620;

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [formData, setFormData] = useState({ username: '', password: '' })

    const { requestStatus, requestError } = useSelector(state => state.login)
    const adminLogin = useSelector(state => state.admin_login)
    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (requestStatus === 'success') window.location.reload()
        if (adminLogin.requestStatus === 'success') window.location.href = '/admin/dashboard'
        if (user) navigate("/dashboard", { replace: true })
    }, [requestStatus, adminLogin, user, navigate])

    const handleChange = (e, name) => {
        setFormData((prevState) => ({ ...prevState, [name]: e.target.value }))
    }
    const onSubmit = (e) => {
        e.preventDefault()

        const { username, password } = formData
        dispatch(userSignin({ username, password }))
    }
    const adminSubmit = () => {
        const { username, password } = formData
        dispatch(adminSignin({ username, password }))
    }

    return (
        <>
            {width < breakpoint ?
                <MobileContainer>
                    <Navbar />

                    <div className="form-container">
                        <h3>Login</h3>
                        <p>Please sign in to continue</p>
                        <LoginForm>
                            <input
                                type="text"
                                placeholder="Username"
                                name="username"
                                value={formData.username}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['username'] ? requestError['username'] : ""}</small>) : null}
                            {adminLogin.requestError ? (<small>{adminLogin.requestError['username'] ? adminLogin.requestError['username'] : ""}</small>) : null}
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                value={formData.password}
                                onChange={(e) => handleChange(e, e.target.name)}
                            />
                            {requestError ? (<small>{requestError['password'] ? requestError['password'] : ""}</small>) : null}
                            {adminLogin.requestError ? (<small>{adminLogin.requestError['password'] ? adminLogin.requestError['password'] : ""}</small>) : null}

                            <NavLink to="">Forgot Password?</NavLink>
                            <span
                                className="admin-btn"
                                onClick={adminSubmit}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    padding: '5px 20px',
                                    backgroundColor: '#333',
                                    color: 'white',
                                    width: '30%',
                                    fontSize: '14px',
                                    textAlign: 'center',
                                    border: 'none',
                                    borderRadius: '2px',
                                    marginBottom: '10px'
                                }}
                            >admin?</span>
                            <button
                                onClick={onSubmit}
                            >Login</button>
                        </LoginForm>
                    </div>
                </MobileContainer>
                :
                <DesktopContainer>
                    <Header />
                    <DesktopLogin>
                        <h3>Login</h3>
                        {requestStatus === 'pending' && <Loader />}
                        <input
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={formData.username}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['username'] ? requestError['username'] : ""}</small>) : null}
                        {adminLogin.requestError ? (<small>{adminLogin.requestError['username'] ? adminLogin.requestError['username'] : ""}</small>) : null}
                        <input
                            type="password"
                            placeholder="Password"
                            name="password"
                            value={formData.password}
                            onChange={(e) => handleChange(e, e.target.name)}
                        />
                        {requestError ? (<small>{requestError['password'] ? requestError['password'] : ""}</small>) : null}
                        {adminLogin.requestError ? (<small>{adminLogin.requestError['password'] ? adminLogin.requestError['password'] : ""}</small>) : null}

                        <NavLink to="">Forgot Password?</NavLink>
                        <span
                            className="admin-btn"
                            onClick={adminSubmit}
                        >admin?</span>
                        <button
                            onClick={onSubmit}
                        >Login</button>
                    </DesktopLogin>
                    <Footer />
                </DesktopContainer>
            }
        </>

    );
}

const DesktopContainer = styled.div`
    max-width: 100%;
`
const DesktopLogin = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    padding: 0 2rem;
    max-width: 100%;
    width: 50%;

    h3 {
        text-align: center;
        color: #D9745B;
    }

    a {
        text-decoration: none;
        color: black;
        text-align: left;
        margin: 1rem 0;
        font-size: 0.8rem;
    }

    input {
        padding: 1rem;
        margin: 0.5rem 0;
        border: 0.5px solid grey;
        width: 100%;

        &:hover {
            border-color: #404295;
        }

    }

    button {
        width: 30%;
        margin: 0 auto;
        padding: 0.8rem;
        background-color: #404295;
        color: white;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

    .admin-btn {
        width: 20%;
        font-size: 14px;
        padding: 5px 10px;
        background-color: #333;
        color: white;
        border: none;
        border-radius: 2px;
        text-align: center;
        &:hover {
            cursor: pointer;
        }
    }
`

const MobileContainer = styled.div`
    text-align: center;
    max-width: 619px;

    .form-container {

        margin-top: 3rem;
    }

    h3 {
        color: #D9745B;
    }

`
const LoginForm = styled.form`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 2rem;
    max-width: 100%;

    a {
        text-decoration: none;
        color: black;
        text-align: left;
        margin: 1rem 0;
        font-size: 0.8rem;
    }

    input {
        padding: 1rem;
        margin: 0.5rem 0;
        border: 0.5px solid grey;
        width: 100%;

        &:hover {
            border-color: #404295;
        }

    }

    button {
        width: 100%;
        margin: 0 auto;
        padding: 0.8rem;
        background-color: #404295;
        color: white;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

`

export default Login;