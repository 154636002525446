import React from 'react'
import Campaign from './Campaign';
import Slider from 'react-slick';
import styled from 'styled-components';

const PreviousBtn = (props) => {
    // console.log(props);
    const { className, onClick } = props;
    return (
        <PrevBtnStyle className={className} onClick={onClick}>
            <i className="fas fa-chevron-left"></i>
        </PrevBtnStyle>
    );
};

const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
        <NextBtnStyle className={className} onClick={onClick}>
            <i className="fas fa-chevron-right" />
        </NextBtnStyle>
    );
};

const CampaignCarousel = ({ campaigns }) => {

    // let settings = {
    //     dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 1,
    //     slidesToScroll: 1,
    //     nextArrow: <NextBtn />
    // };

    return (
        <>
            <Heading>Campaigns</Heading>
            <DonorSection>
                <Slider className="donor-slider" prevArrow={<PreviousBtn />} nextArrow={<NextBtn />} infinite={true} >
                    {campaigns.length > 0 ? campaigns.map(campaign => (
                        <Campaign img={campaign.imageUrl} key={campaign._id} campaign={campaign} />
                    )) : (
                        <Campaign img="./old-man.png" />
                    )}
                </Slider>
                {/* <Campaign img="./donor-1.png" /> */}
            </DonorSection>
        </>
    )
}

const Heading = styled.h3`
    text-align: center;
    color: #D9745B;
`

const PrevBtnStyle = styled.button`
    border: none;
    color: #404295;
    font-size: 2rem;
    background-color: white;
    position: absolute;
    top: 200px;
    left: 20px;
`

const NextBtnStyle = styled.button`
    border: none;
    color: #404295;
    font-size: 2rem;
    background-color: white;
    position: absolute;
    right: 20px;
    top: 200px;
`

const DonorSection = styled.section`
    position: relative;
    display: flex;
    justify-content: center;

    .donor-slider {
        width: 80%;
    }

    h3 {
        text-align: center;
        color: #D9745B;
    }

    .slick-prev::before,
    .slick-next::before {
        display: none;
    }

    .slick-arrow.slick-prev {
        z-index: 1;
        color: #404295;
    }
    .slick-arrow.slick-next {
        z-index: 1;
        color: #404295;
    }
`

export default CampaignCarousel
