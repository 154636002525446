import React from 'react'
import Footer from '../components/Footer'
import styled from 'styled-components'
import AchievementsCarousel from '../components/AchievementsCarousel'
import useWindowSize from '../utils/useWindowSize'
import Navbar from '../components/Navbar'
import Header from '../components/Header'

const About = () => {

    const { width } = useWindowSize();
    const breakpoint = 620;

    return (
        <>
            {width < breakpoint ? <Navbar /> : <Header />}
            
            <AboutSection>
                <h4>About Cheer Bequest Ltd.</h4>
                <p>
                    Cheer Bequest is a donation bases fundraising and crowd funding platform, where individuals, NGO'S and other organizations with causes, problems and projects can easily raise funds earnestly. By using our go-to portal for crowd funding in Nigeria and the countries were operating so far, Charities, School Alumnus, Religious organizations, Entrepreneurs and other game-changers can ensure their big dreams come true, by enlisting the help of people all over the world.<br /><br />

                    Cheer Bequest is duly registered under the Cooperate Affairs Commission of Nigeria RC No. 1850062. Our focus and goal is to ensure no small & huge problems goes unfunded... Cheer Bequest has no hidden or extra Fees. Registration as a member and creating a donation portal is free, but for the service, and the support we provide, Cheer Bequest Service fee is 20% from each donation you receive for upkeep and running of this platform.<br /><br />

                    Cheer Bequest charges 20% off each donation received, this 20% includes the platform fee (15%) + payment processor fee (5%),  also note that some charges will take place from the campaigners balance upon money withdrawal. Withdrawals are processed within 24 hours and sent to your bank on the next working day. But could take up to 7 working days for your bank to credit your bank account.By continuing, you agree to the Cheer Bequest terms and acknowledge receipt of our privacy policy. The concerned fees are deducted from the donations before remitted to the beneficiary. The Donors are charged exactly the amount they choose to donate.
                </p>

                {width < breakpoint ? 
                
                    <MissionSectionMobile>
                        <h3>Our Mission/Vision</h3>
                        <MissionImageMobile>
                            <img src="./mission.png" alt="mission" />
                        </MissionImageMobile>
                        <MissionInfoMobile>
                            <p>
                                our mission at cheerbequest.com is to build a suitable, reliable and secured platform that will assist businesses and individuals in terms of raising fund for their cases and businesses. We hope to make life and survival easy for Nigeria and other countries.<br/>
                                our vision at cheerbequest.com is to become a household name and recognized leader in our targeted market as a platform where the Needy (All Over the World) can raise funds for their causes and projects, and also where individuals and entrepreneurs can easily acquire all necessary funds incentives they need for their occasions and businesses for free.
                            </p>
                        </MissionInfoMobile>
                    </MissionSectionMobile> 
                
                :
                    <MissionSection>
                        <MissionImage>
                            <img src="./mission.png" alt="mission" />
                        </MissionImage>
                        <MissionInfo>
                            <h3>Our Mission/Vision</h3>
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet egestas vulputate iaculis turpis et nibh malesuada. Neque fermentum mattis auctor mi. Non eget nec, nam risus purus libero, vulputate. Quis eget hac maecenas quisque amet, fringilla. Euismod enim venenatis sollicitudin laoreet tristique. Volutpat commodo pretium nibh id rutrum. Eu consequat diam ipsum facilisis. Risus ultricies eget facilisis vel. Egestas sodales purus odio ullamcorper aenean consequat turpis massa. Aliquam magna suspendisse dapibus nullam convallis.
                                Vitae at aliquam nibh nulla luctus egestas placerat vestibulum. Vulputate malesuada pharetra et malesuada tellus in malesuada mattis. Tempor, dui, ipsum vel dis feugiat in amet ipsum leo. Amet vel volutpat, ac non volutpat nisl. Leo velit sed ultricies eu porta mauris, amet. At adipiscing augue integer sed iaculis habitant eget sit. Lacus at cras duis sed nam pharetra amet.
                            </p>
                        </MissionInfo>
                    </MissionSection>
                }
                <AchievementsCarousel />
            </AboutSection>
            <Footer />
        </>
    )
}

const AboutSection = styled.section`
    padding: 2rem 2rem;

    h4 {
        text-align: center;
        color: #D9745B;
    }
`

const MissionSection = styled.section`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5rem 0;


`
const MissionSectionMobile = styled.section`
    display: flex;
    flex-direction: column;
    margin: 3rem auto;
    align-items: center;
    width: 100%;

    h3 {
        color: #D9745B;
    }
`

const MissionImage = styled.div`
    width: 40%;
    margin-right: 2rem;

    img {
        width: 100%;
    }
`
const MissionImageMobile = styled.div`
    width: 100%;
    margin: 0 2rem;

    img {
        width: 100%;
    }
`

const MissionInfo = styled.div`
    width: 50%;

    h3 {
        color: #D9745B;
    }
`
const MissionInfoMobile = styled.div`
    width: 100%;

`

export default About
