import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { adminLogout } from '../../state/actions/admin.actions'

const Aside = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { user } = useSelector(state => state.admin_auth)

    useEffect(() => {
        if (!user) navigate('/', { replace: true })
    }, [user, navigate])

    const logoutAdmin = (e) => {
        e.preventDefault()
        dispatch(adminLogout())
        window.location.reload()
    }

    return (
        <Container>
            <Main>
                <LogoContainer>
                    <img src="/logo.png" alt="cheer bequest" />
                </LogoContainer>
                <AsideNavigation>
                    <NavLink to="/" className={(navData) => navData.isActive ? "active" : ""}>
                        <img src="/home.png" alt="home" />
                        <div>Home</div>
                    </NavLink>

                    {user && <NavLink to="/dashboard">
                        <img src="/clarity_dashboard-line.png" alt="dashboard" />
                        <div>Dashboard</div>
                    </NavLink>}
                    <NavLink to="/my-campaigns">
                        <img src="/cil_book.png" alt="campaigns" />
                        <div>My Campaigns</div>
                    </NavLink>
                    <NavLink to="/donations">
                        <img src="/cil_book.png" alt="campaigns" />
                        <div>Donations</div>
                    </NavLink>
                    {/* <NavLink to="/account-details">
                        <img src="/cil_book.png" alt="campaigns" />
                        <div>Account Details</div>
                    </NavLink>
                    <NavLink to="/pledges">
                        <img src="/compass.png" alt="donations" />
                        <div>Pledges Received</div>
                    </NavLink> */}
                    {/* <NavLink to="/payment-methods">
                        <img src="/compass.png" alt="donations" />
                        <div>Payment Methods</div>
                    </NavLink> */}
                    {/* <NavLink to="/settings">
                        <img src="/compass.png" alt="settings" />
                        <div>Settings</div>
                    </NavLink> */}
                    {/* <NavLink to="/admin/approve-campaigns">
                        <img src="/check-square.png" alt="approved" />
                        <div>Approve Campaign</div>
                    </NavLink>
                    <NavLink to="/admin/withdraw-campaigns">
                        <img src="/shuffle.png" alt="withdrawn" />
                        <div>Withdraw Campaign</div>
                    </NavLink> */}


                    <NavLink to="_" onClick={logoutAdmin}>
                        <img src="/log-out.png" alt="logout" />
                        <div>Logout</div>
                    </NavLink>
                </AsideNavigation>
            </Main>
        </Container>
    )
}

const Container = styled.div`
    max-width: 30%;
    width: 30%;
    background-color: #F4F5FF;
    height: 100%;
    margin-right: 2rem;
`
const Main = styled.div`
    display: flex;
    flex-direction: column;
`

const LogoContainer = styled.div`
    width: 5rem;
    height: 5rem;
    margin: 2rem 2rem;
    
    img {
        width: 100%;
        height: 100%;
    }
`

const AsideNavigation = styled.div`
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    // padding-left: 2rem;
    justify-content: space-between;

    a {
        text-decoration: none;
        color: #333333;
        display: flex;
        align-items: center;
        padding-left: 2rem;
    }
    img {
        width: 2rem;
        height: 2rem;
    }

    .active {

        img {

            filter: invert(100%);
        }
        background-color: #404295;
        color: white;
        li {
        }
    }

    div {
        padding: 1rem;
        // border: 1px solid grey;
    }
`

export default Aside
