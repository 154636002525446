import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import AdminNav from '../components/AdminNav'
import Footer from '../components/Footer'
import NavAside from '../components/NavAside'
import { getCampaigns } from '../state/actions/campaign.actions'
import useWindowSize from '../utils/useWindowSize'
import { Pagination } from './FundSomeone'
import Moment from 'moment'
import { Link } from 'react-router-dom'

const Campaigns = () => {

    const { width } = useWindowSize()
    const breakpoint = 620;

    const dispatch = useDispatch()
    const moment = Moment()
    const { campaigns } = useSelector(state => state.campaigns)

    useEffect(() => {
        dispatch(getCampaigns())
    }, [dispatch])

    return (
        <div>
            {width < breakpoint ?
                <MobileDashboard>
                    <AdminNav />
                    <Date>
                        <h5>Monday, 7th December 2021</h5>
                    </Date>

                    <Search>
                        <input type="text" placeholder="search" />
                        <button type="submit">
                            <img src="/search.png" alt="" />
                        </button>
                    </Search>

                    <CategoryDropdown>
                        <select name="category">
                            <option value="agriculture">Agriculture</option>
                            <option value="health">Health</option>
                        </select>
                    </CategoryDropdown>

                    <CampaignContainer>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Funding Goal</th>
                                    <th>Start Date</th>
                                    <th>End Date</th>
                                    <th>Progress</th>
                                    <th>Withdraw</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaigns ? campaigns.map(campaign => (
                                    <tr key={campaign._id}>
                                        <td>{campaign.title}</td>
                                        <td>{campaign.fundingGoal}</td>
                                        <td>{moment.format('YYYY-MM-DD', campaign.startDate)}</td>
                                        <td>{moment.format('YYYY-MM-DD', campaign.endDate)}</td>
                                        <td>0%</td>
                                        <td>
                                            <Link
                                                style={{
                                                    textDecoration: 'none',
                                                    cursor: 'pointer',
                                                    padding: '5px',
                                                    color: 'white',
                                                    background: 'grey',
                                                    borderRadius: '2px'
                                                }}
                                                to={`/admin/withdraw-campaign/${campaign._id}`}
                                            >withdraw</Link>
                                        </td>
                                    </tr>
                                )) : (
                                    <tr>
                                        <td>Daniel Bemson</td>
                                        <td>500,000NGN</td>
                                        <td>13th Nov, 2020</td>
                                        <td>27th Dec, 2020</td>
                                        <td>15%</td>
                                    </tr>
                                )}
                                {/* <tr>
                                    <td>Daniel Bemson</td>
                                    <td>500,000NGN</td>
                                    <td>13th Nov, 2020</td>
                                    <td>27th Dec, 2020</td>
                                    <td>15%</td>
                                </tr>
                                <tr>
                                    <td>Daniel Bemson</td>
                                    <td>500,000NGN</td>
                                    <td>13th Nov, 2020</td>
                                    <td>27th Dec, 2020</td>
                                    <td>15%</td>
                                </tr>
                                <tr>
                                    <td>Daniel Bemson</td>
                                    <td>500,000NGN</td>
                                    <td>13th Nov, 2020</td>
                                    <td>27th Dec, 2020</td>
                                    <td>15%</td>
                                </tr>
                                <tr>
                                    <td>Daniel Bemson</td>
                                    <td>500,000NGN</td>
                                    <td>13th Nov, 2020</td>
                                    <td>27th Dec, 2020</td>
                                    <td>15%</td>
                                </tr> */}
                            </tbody>
                        </table>
                    </CampaignContainer>


                    <div>
                        <Pagination>
                            <button>1</button>
                            <button>2</button>
                            <button>3</button>
                        </Pagination>
                    </div>
                    <Footer />
                </MobileDashboard>
                :
                <DesktopDashboard>
                    <main>
                        <NavAside />
                        <MainSection>
                            <DesktopInfo>
                                <div>
                                    <h4>Hello!</h4>
                                    <p>
                                        20% of each donations funding your campaigns will be deducted for the support of the platform
                                    </p>
                                </div>
                                <div>
                                    <Date>
                                        <h5>Monday, 7th December 2021</h5>
                                    </Date>
                                </div>
                            </DesktopInfo>

                            <DesktopSearch>
                                <input type="text" placeholder="search" />
                                <button type="submit">
                                    <img src="/search.png" alt="" />
                                </button>
                            </DesktopSearch>

                            <CategoryDropdown>
                                <select name="category">
                                    <option value="agriculture">Agriculture</option>
                                    <option value="health">Health</option>
                                </select>
                            </CategoryDropdown>

                            <CampaignContainer>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Funding Goal</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Progress</th>
                                            <th>Aprrove</th>
                                            <th>Withdraw</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {campaigns ? campaigns.map(campaign => (
                                            <tr key={campaign._id}>
                                                <td>{campaign.title}</td>
                                                <td>{campaign.fundingGoal}</td>
                                                <td>{moment.format('YYYY-MM-DD', campaign.startDate)}</td>
                                                <td>{moment.format('YYYY-MM-DD', campaign.endDate)}</td>
                                                <td>0%</td>
                                                <td>
                                                    {campaign.approved ? 'approved' : (
                                                        <Link
                                                            style={{
                                                                textDecoration: 'none',
                                                                cursor: 'pointer',
                                                                padding: '5px',
                                                                color: 'white',
                                                                background: 'grey',
                                                                borderRadius: '2px'
                                                            }}
                                                            to={`/admin/approve-campaign/${campaign._id}`}
                                                        >approve</Link>
                                                    )}
                                                </td>
                                                <td>
                                                    <Link
                                                        style={{
                                                            textDecoration: 'none',
                                                            cursor: 'pointer',
                                                            padding: '5px',
                                                            color: 'white',
                                                            background: 'grey',
                                                            borderRadius: '2px'
                                                        }}
                                                        to={`/admin/withdraw-campaign/${campaign._id}`}
                                                    >withdraw</Link>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td>Daniel Bemson</td>
                                                <td>500,000NGN</td>
                                                <td>13th Nov, 2020</td>
                                                <td>27th Dec, 2020</td>
                                                <td>15%</td>
                                            </tr>
                                        )}
                                        {/* <tr>
                                            <td>Daniel Bemson</td>
                                            <td>500,000NGN</td>
                                            <td>13th Nov, 2020</td>
                                            <td>27th Dec, 2020</td>
                                            <td>15%</td>
                                        </tr>
                                        <tr>
                                            <td>Daniel Bemson</td>
                                            <td>500,000NGN</td>
                                            <td>13th Nov, 2020</td>
                                            <td>27th Dec, 2020</td>
                                            <td>15%</td>
                                        </tr>
                                        <tr>
                                            <td>Daniel Bemson</td>
                                            <td>500,000NGN</td>
                                            <td>13th Nov, 2020</td>
                                            <td>27th Dec, 2020</td>
                                            <td>15%</td>
                                        </tr>
                                        <tr>
                                            <td>Daniel Bemson</td>
                                            <td>500,000NGN</td>
                                            <td>13th Nov, 2020</td>
                                            <td>27th Dec, 2020</td>
                                            <td>15%</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </CampaignContainer>


                            <div>
                                <Pagination>
                                    <button>1</button>
                                    <button>2</button>
                                    <button>3</button>
                                </Pagination>
                            </div>
                        </MainSection>
                    </main>

                    <Footer />
                </DesktopDashboard>
            }
        </div>
    )
}

const MobileDashboard = styled.div`
    font-size: 0.8rem;
    color: #333333;
    p {
        color: #333333;
    }
`

const Date = styled.div`
    text-align: center;
    h5 {
        color: #D9745B;
    }
`

const Search = styled.form`
    display: flex;
    justify-content: center;
    margin: 0 2rem;
    position: relative;

    input {
        width: 100%;
        padding: 0.5rem;
        border-radius: 1rem;
        border: 1px solid grey;
        outline: none;

        &:focus {
            border: 1px solid #404295;
        }
    }

    button {
        border: none;
        background: none;
        position: absolute;
        right: 3%;
        top: 20%;
    }
`

const CategoryDropdown = styled.form`
    margin: 2rem;

    select {
        padding: 0.5rem;
        border: none;
        background: none;
        outline: none;
        color: #D9745B;
    }
`

const CampaignContainer = styled.div`
    overflow-x: auto;
    padding: 0 2rem;

    table {
        border-collapse: collapse;
    }
    th {
        padding: 0.8rem;
        background-color: #dddddd;
    }
    tr {
        border: 1px solid #dddddd;
        width: 100%;
    }
    td {
        padding: 1rem;
    }
`

// Desktop view styles
const DesktopDashboard = styled.div`

    main {

        display: flex;
    }
`

const MainSection = styled.div`
    margin-right: 2rem;
`

const DesktopInfo = styled.div`
    display: flex;
    justify-content: space-between;
`

const DesktopSearch = styled.form`
    display: flex;
    justify-content: flex-start;
    margin: 0 2rem;
    position: relative;

    input {
        width: 40%;
        padding: 0.5rem;
        border-radius: 1rem;
        border: 1px solid grey;
        outline: none;

        &:focus {
            border: 1px solid #404295;
        }
    }

    button {
        border: none;
        background: none;
        position: absolute;
        right: 62%;
        top: 22%;
    }
`

export default Campaigns
