import React from 'react'
import styled from 'styled-components'
import { useViewport } from '../context/ViewportContext'
import { Link, NavLink } from 'react-router-dom'
import { Navigation } from './styles/Navigation.styled'
import { useSelector } from 'react-redux'

const Navbar = () => {

    const [open, setOpen] = React.useState(false);
    const node = React.useRef();

    const { width } = useViewport()
    const breakpoint = 620;

    const { user } = useSelector(state => state.auth)
    const adminAuth = useSelector(state => state.admin_auth)
    // const useOnClickOutside = (ref, handler) => {
    //     React.useEffect(() => {
    //         const listener = event => {
    //         if (!ref.current || ref.current.contains(event.target)) {
    //             return;
    //         }
    //         handler(event);
    //         };
    //         document.addEventListener('mousedown', listener);

    //         return () => {
    //         document.removeEventListener('mousedown', listener);
    //         };
    //     },
    //     [ref, handler],
    //     );
    // };


    return (
        <>
            {width < breakpoint ?
                <MobileNav style={{ background: 'none' }}>
                    <LogoContainer>
                        <Link to="/"><img src="/logo.png" alt="cheer bequest logo" /></Link>
                    </LogoContainer>
                    <div className="burger" ref={node}>
                        <Burger open={open} setOpen={setOpen} />
                        <Menu open={open} setOpen={setOpen} />
                    </div>
                </MobileNav>
                :
                <Navigation>
                    <div>
                        <NavUnlisted>
                            <NavLink to="/" className={(navData) => navData.isActive ? "active" : ""}><li>Home</li></NavLink>
                            {adminAuth.user && <NavLink to="/admin/dashboard" className="current"><li>Admin Dashboard</li></NavLink>}
                            <NavLink to="/dashboard" className="current"><li>Dashboard</li></NavLink>
                            {user && (
                                <>
                                    <NavLink to="/create-campaign" className="current"><li>Create Campaign</li></NavLink>
                                    <NavLink to="/fund-someone" className="current"><li>Fund Someone</li></NavLink>
                                    <NavLink to="/donations" className="current"><li>Donations</li></NavLink>
                                </>
                            )}
                            <NavLink to="/about-us" className="current"><li>About Us</li></NavLink>
                            <NavLink to="/contact-us" className="current"><li>Contact</li></NavLink>
                            <NavLink to="/how-it-works" className="current"><li>How It Works</li></NavLink>
                            {/* <NavLink to="/donations" className="current"><li>Donations</li></NavLink> */}
                            {/* <NavLink to="/categories" className="current"><li>Categories</li></NavLink> */}
                        </NavUnlisted>
                    </div>
                </Navigation>
            }
        </>
    )
}

const Burger = ({ open, setOpen }) => {
    return (
        <StyledBurger open={open} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
        </StyledBurger>
    )
}

const Menu = ({ open }) => {
    const { user } = useSelector(state => state.auth)
    const adminAuth = useSelector(state => state.admin_auth)

    return (
        <StyledMenu open={open}>
            <div>
                <NavUnlistedMobile>
                    <NavLink to="/" className={(navData) => navData.isActive ? "active" : ""}><li>Home</li></NavLink>
                    {adminAuth?.user && <NavLink to="/admin/dashboard" className="current"><li>Admin Dashboard</li></NavLink>}
                    <NavLink to="/dashboard" className="current"><li>Dashboard</li></NavLink>
                    {user && (
                        <>
                            <NavLink to="/create-campaign" className="current"><li>Create Campaign</li></NavLink>
                            <NavLink to="/fund-someone" className="current"><li>Fund Someone</li></NavLink>
                        </>
                    )}
                    <NavLink to="/about-us" className="current"><li>About Us</li></NavLink>
                    <NavLink to="/contact-us" className="current"><li>Contact</li></NavLink>
                    <NavLink to="/how-it-works" className="current"><li>How It Works</li></NavLink>
                    {/* <NavLink to="/donations" className="current"><li>Donations</li></NavLink> */}
                    {!user && (
                        <>
                            <NavLink to="/register" className="current"><li>Register</li></NavLink>
                            <NavLink to="/login" className="current"><li>Login</li></NavLink>
                        </>
                    )}
                    {/* <NavLink to="/categories" className="current"><li>Categories</li></NavLink> */}
                </NavUnlistedMobile>
            </div>
        </StyledMenu>
    )
}

const MobileNav = styled.nav`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 2rem;
    background-color: #404295;
    max-width: 100%;
    color: white;

    .burger {
        margin: 0.5rem 2rem;
    }
`

const StyledMenu = styled.nav`
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #F4F5FF;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    height: 100vh;
    text-align: left;
    padding: 2rem;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.3s ease-in-out;

    @media (max-width: 576px) {
        width: 100%;
    }

    a {
        font-size: 2rem;
        text-transform: uppercase;
        padding: 2rem 0;
        font-weight: bold;
        letter-spacing: 0.5rem;
        color: #0D0C1D;
        text-decoration: none;
        transition: color 0.3s linear;

        @media (max-width: 576px) {
        font-size: 1.5rem;
        text-align: center;
        }

        &:hover {
        color: #343078;
        }
    }
`

const StyledBurger = styled.button`
    position: absolute;
    // top: 5%;
    // left: 34rem;
    // margin: 0.5rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 2rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;


    &:focus {
        outline: none;
    }
    // #0D0C1D #EFFFFA
    div {
        width: 2rem;
        height: 0.25rem;
        background: ${({ open }) => open ? '#404295' : '#404295'};
        border-radius: 10px;
        transition: all 0.3s linear;
        position: relative;
        transform-origin: 1px;

        :first-child {
            transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
        }

        :nth-child(2) {
            opacity: ${({ open }) => open ? '0' : '1'};
            transform: ${({ open }) => open ? 'translateX(20px)' : 'translateX(0)'};
        }

        :nth-child(3) {
            transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
        }
    }
`

const NavUnlisted = styled.ul`
    display: flex;

    a {
        text-decoration: none;
        color: white;
    }

    li {
        margin: 0 1rem;
        list-style: none;

        &:hover {
            color: #D9745B;
            
        }
        
    }

    .active {
        color: #D9745B;
        li {
        }
    }

`

const NavUnlistedMobile = styled.ul`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    li {
        font-size: 0.7rem;
        list-style: none;
    }
`

const LogoContainer = styled.div`
    width: 3rem;
    height: 3rem;
    z-index: 10;
    // position: absolute;

    img {
        width: 100%;
        height: 100%;
    }
`

export default Navbar
