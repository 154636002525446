import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
// import AdminNav from '../../components/AdminNav';
import Footer from '../../components/Footer';
// import NavAside from '../../components/NavAside';
import Aside from '../../components/users/Aside';
import Nav from '../../components/users/Nav';
import useWindowSize from '../../utils/useWindowSize';
import { Pagination } from '../FundSomeone';

const Dashboard = () => {

    const { width } = useWindowSize()
    const breakpoint = 620;

    const navigate = useNavigate()

    const { user } = useSelector(state => state.auth)

    useEffect(() => {
        if (!user) navigate('/')
    }, [user, navigate])

    console.log(user);
    return (
        <div>
            {width < breakpoint ?
                <MobileDashboard>
                    <Nav />
                    <Date>
                        <h5>Monday, 7th December 2021</h5>
                    </Date>

                    <CardContainer>
                        <div>
                            <h3>Total Campaigns</h3>
                            {/* <p>{campaigns ? campaigns.length : 0}</p> */}
                        </div>
                        <div>
                            <h3>Total Funds Raised</h3>
                            <p>0.00 NGN</p>
                        </div>
                        <div>
                            <h3>Total 20% Deduction</h3>
                            <p>0.00 NGN</p>
                        </div>
                    </CardContainer>

                    <StatementSection>
                        <h3>Statement of All Campaigns</h3>
                        <div>
                            <table>
                                <tbody>
                                    <tr>
                                        <td>Total Donations</td>
                                        <td>0.00 NGN</td>
                                    </tr>
                                    <tr>
                                        <td>Total Amount Raised</td>
                                        <td>0.00NGN</td>
                                    </tr>
                                    <tr>
                                        <td>Total Amount of each 20% Deduction</td>
                                        <td>0.00 NGN</td>
                                    </tr>
                                    <tr>
                                        <td>Total Campaign Funds</td>
                                        <td>0.00NGN</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </StatementSection>

                    <Buttons>
                        <CreateBtn>Create Campaign</CreateBtn>
                        <FundBtn>Fund Someone</FundBtn>
                    </Buttons>

                    <RecentSection>
                        <h3>Recent Activities</h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>30th Nov</td>
                                    <td>You approved Daniel Benson's Campaign</td>
                                </tr>
                                <tr>
                                    <td>30th Nov</td>
                                    <td>Miss Daura Kane applied for withdrawal of campaign</td>
                                </tr>
                                <tr>
                                    <td>30th Nov</td>
                                    <td>Anonymous backed Daniel Benson's campaign with 10,000NGN</td>
                                </tr>
                                <tr>
                                    <td>30th Nov</td>
                                    <td>Ms. Gorgeous backed Kate Luke's campaign with 2,500NGN</td>
                                </tr>
                            </tbody>
                        </table>
                    </RecentSection>
                    <div>
                        <Pagination>
                            <button>1</button>
                            <button>2</button>
                            <button>3</button>
                        </Pagination>
                    </div>
                    <Footer />
                </MobileDashboard>
                :
                <DesktopDashboard>
                    <main>
                        <Aside />
                        <MainSection>
                            <DesktopInfo>

                                <div>
                                    <Date>
                                        <h5>Monday, 7th December 2021</h5>
                                    </Date>
                                </div>
                            </DesktopInfo>
                            <div>
                                <CardContainer>
                                    <div>
                                        <h3>Total Campaigns</h3>
                                        {/* <p>{campaigns ? campaigns.length : 0}</p> */}
                                    </div>
                                    <div>
                                        <h3>Total Funds Raised</h3>
                                        <p>0.00 NGN</p>
                                    </div>
                                    <div>
                                        <h3>Total 20% Deduction</h3>
                                        <p>0.00 NGN</p>
                                    </div>
                                </CardContainer>
                            </div>
                            <DesktopStatementSection>
                                <h3>Statement of All Campaigns</h3>
                                <div>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td>Total Donations</td>
                                                <td>0.00 NGN</td>
                                            </tr>
                                            <tr>
                                                <td>Total Amount Raised</td>
                                                <td>0.00NGN</td>
                                            </tr>
                                            <tr>
                                                <td>Total Amount of each 20% Deduction</td>
                                                <td>0.00 NGN</td>
                                            </tr>
                                            <tr>
                                                <td>Total Campaign Funds</td>
                                                <td>0.00NGN</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </DesktopStatementSection>

                            <Buttons>
                                <CreateBtn>Create Campaign</CreateBtn>
                                <FundBtn>Fund Someone</FundBtn>
                            </Buttons>

                            <DesktopRecentSection>
                                <h3>Recent Activities</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Date</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>30th Nov</td>
                                            <td>You approved Daniel Benson's Campaign</td>
                                        </tr>
                                        <tr>
                                            <td>30th Nov</td>
                                            <td>Miss Daura Kane applied for withdrawal of campaign</td>
                                        </tr>
                                        <tr>
                                            <td>30th Nov</td>
                                            <td>Anonymous backed Daniel Benson's campaign with 10,000NGN</td>
                                        </tr>
                                        <tr>
                                            <td>30th Nov</td>
                                            <td>Ms. Gorgeous backed Kate Luke's campaign with 2,500NGN</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </DesktopRecentSection>
                            <div>
                                <Pagination>
                                    <button>1</button>
                                    <button>2</button>
                                    <button>3</button>
                                </Pagination>
                            </div>
                        </MainSection>
                    </main>

                    <Footer />
                </DesktopDashboard>
            }
        </div>
    )
}


const MobileDashboard = styled.div`
    font-size: 0.8rem;
    color: #333333;
    p {
        color: #333333;
    }
`

const Date = styled.div`
    text-align: center;
    h5 {
        color: #D9745B;
    }
`

const Buttons = styled.div`
    display: flex;
    padding: 2rem;
    justify-content: center;

    @media(max-width:620px) {
        flex-direction: column;
        justify-content: center;

        button {
            margin-bottom: 1rem;
            margin-right: 0;
        }
    }

`
const CreateBtn = styled.button`
    padding: 0.8rem 1rem;
    margin-right: 1rem;
    background: #404295;
    border: none;
    color: white;
    border-radius: 0.5rem;
`
const FundBtn = styled.button`
    padding: 0.8rem 1rem;
    margin-right: 1rem;
    border: 1px solid #404295;
    border-radius: 0.5rem;
    background: none;
    color: #404295;
`


const CardContainer = styled.div`
    padding: 0.5rem 2rem;

    div {
        box-shadow: 1px 1px 3px grey;
        display: flex;
        flex-direction: column;
        text-align: center;
        margin: 1rem 0;

    }

    @media(min-width: 620px) {
        display: flex;
        justify-content: space-between;
        padding: 0;
        
        div {
            margin-right: 2rem;
            padding: 1rem 2rem;
        }
    }
`
const StatementSection = styled.section`
    padding: 0.5rem 2rem;

    h3 {
        color: #D9745B;
    }

    table {
        border-collapse: collapse;
    }
    tr {
        border: 1px solid #dddddd;
    }
    td {
        padding: 1rem;
    }
`

const RecentSection = styled.section`
    padding: 0.5rem 2rem;

    h3 {
        color: #D9745B;
    }

    table {
        border-collapse: collapse;
    }
    th {
        padding: 0.8rem;
        background-color: #dddddd;
    }
    tr {
        border: 1px solid #dddddd;
    }
    td {
        padding: 1rem;
    }
`

// Desktop view styles
const DesktopDashboard = styled.div`

    main {

        display: flex;
    }
`

const MainSection = styled.div`
    margin-right: 2rem;
`

const DesktopInfo = styled.div`
    display: flex;
    justify-content: space-between;
`

const DesktopStatementSection = styled.section`
    h3 {
        color: #D9745B;
    }

    table {
        border-collapse: collapse;
        width: 100%;
    }
    tr {
        border: 1px solid #dddddd;
    }
    td {
        padding: 1rem;
    }
`
const DesktopRecentSection = styled.section`

    h3 {
        color: #D9745B;
    }

    table {
        border-collapse: collapse;
    }
    th {
        padding: 0.8rem;
        background-color: #dddddd;
    }
    tr {
        border: 1px solid #dddddd;
    }
    td {
        padding: 1rem;
    }
`

export default Dashboard