import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
// import AdminNav from '../../components/AdminNav'
import Footer from '../../components/Footer'
import Header from '../../components/Header'
import Nav from '../../components/users/Nav'
// import NavAside from '../../components/NavAside'
// import Navbar from '../components/Navbar'
import { getDonations } from '../../state/actions/admin.actions'
import useWindowSize from '../../utils/useWindowSize'
import { Pagination } from '../FundSomeone'

const DonationPage = () => {
    const { width } = useWindowSize()
    const breakpoint = 620;

    const dispatch = useDispatch()

    const { donations } = useSelector(state => state.admin_donations)

    useEffect(() => {
        dispatch(getDonations())
    }, [dispatch])

    return (
        <div>
            {width < breakpoint ?
                <MobileDashboard>
                    <Nav />
                    <Date>
                        <h5>Monday, 7th December 2021</h5>
                    </Date>

                    <Search>
                        <input type="text" placeholder="search" />
                        <button type="submit">
                            <img src="/search.png" alt="" />
                        </button>
                    </Search>

                    <CategoryDropdown>
                        <select name="category">
                            <option value="agriculture">Agriculture</option>
                            <option value="health">Health</option>
                        </select>
                    </CategoryDropdown>

                    <CampaignContainer>
                        <table>
                            <thead>
                                <tr>
                                    <th>Donor</th>
                                    <th>Fundraiser</th>
                                    <th>Amount</th>
                                    <th>Date</th>   
                                </tr>
                            </thead>
                            <tbody>
                                {donations && donations?.map(donation => (
                                    <tr key={donation._id}>
                                        <td>{donation.user.fullname}</td>
                                        <td>{donation.campaign.title}</td>
                                        <td>&#8358; {donation.payment_amount}</td>
                                        <td>{donation.paid_at.split('T')[0]}</td>
                                        {/* <td></td> */}
                                    </tr>
                                ))}
                                {/* <tr>
                                    <td>Naomi Jake</td>
                                    <td>Daniel Bemsen</td>
                                    <td>$1000</td>
                                    <td>27th Dec, 2020</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Naomi Jake</td>
                                    <td>Daniel Bemsen</td>
                                    <td>$1000</td>
                                    <td>27th Dec, 2020</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Naomi Jake</td>
                                    <td>Daniel Bemsen</td>
                                    <td>$1000</td>
                                    <td>27th Dec, 2020</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>Naomi Jake</td>
                                    <td>Daniel Bemsen</td>
                                    <td>$1000</td>
                                    <td>27th Dec, 2020</td>
                                    <td></td>
                                </tr> */}
                            </tbody>
                        </table>
                    </CampaignContainer>


                    <div>
                        <Pagination>
                            <button>1</button>
                            <button>2</button>
                            <button>3</button>
                        </Pagination>
                    </div>
                    <Footer />
                </MobileDashboard>
                :
                <DesktopDashboard>
                    <main>
                        <Header />
                        <MainSection>
                            <DesktopInfo>
                                <div>
                                    <h4>Hello!</h4>
                                    <p>
                                        20% of each donations funding your campaigns will be deducted for the support of the platform
                                    </p>
                                </div>
                                <div>
                                    <Date>
                                        <h5>Monday, 7th December 2021</h5>
                                    </Date>
                                </div>
                            </DesktopInfo>

                            <DesktopSearch>
                                <input type="text" placeholder="search" />
                                <button type="submit">
                                    <img src="/search.png" alt="" />
                                </button>
                            </DesktopSearch>

                            <CategoryDropdown>
                                <select name="category">
                                    <option value="agriculture">Agriculture</option>
                                    <option value="health">Health</option>
                                </select>
                            </CategoryDropdown>

                            <CampaignContainer>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Donor</th>
                                            <th>Fundraiser</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {donations && donations?.map(donation => (
                                            <tr key={donation._id}>
                                                <td>{donation.user.fullname}</td>
                                                <td>{donation.campaign.title}</td>
                                                <td>&#8358; {donation.payment_amount}</td>
                                                <td>{donation.paid_at.split('T')[0]}</td>
                                                {/* <td></td> */}
                                            </tr>
                                        ))}
                                        {/* <tr>
                                            <td>Naomi Jake</td>
                                            <td>Daniel Bemsen</td>
                                            <td>$1000</td>
                                            <td>27th Dec, 2020</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Naomi Jake</td>
                                            <td>Daniel Bemsen</td>
                                            <td>$1000</td>
                                            <td>27th Dec, 2020</td>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td>Naomi Jake</td>
                                            <td>Daniel Bemsen</td>
                                            <td>$1000</td>
                                            <td>27th Dec, 2020</td>
                                            <td></td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </CampaignContainer>


                            <div>
                                <Pagination>
                                    <button>1</button>
                                    <button>2</button>
                                    <button>3</button>
                                </Pagination>
                            </div>
                        </MainSection>
                    </main>

                    <Footer />
                </DesktopDashboard>
            }
        </div>
    )
}

const MobileDashboard = styled.div`
    font-size: 0.8rem;
    color: #333333;
    p {
        color: #333333;
    }
`

const Date = styled.div`
    text-align: center;
    h5 {
        color: #D9745B;
    }
`

const Search = styled.form`
    display: flex;
    justify-content: center;
    margin: 0 2rem;
    position: relative;

    input {
        width: 100%;
        padding: 0.5rem;
        border-radius: 1rem;
        border: 1px solid grey;
        outline: none;

        &:focus {
            border: 1px solid #404295;
        }
    }

    button {
        border: none;
        background: none;
        position: absolute;
        right: 3%;
        top: 20%;
    }
`

const CategoryDropdown = styled.form`
    margin: 2rem;

    select {
        padding: 0.5rem;
        border: none;
        background: none;
        outline: none;
        color: #D9745B;
    }
`

const CampaignContainer = styled.div`
    overflow-x: auto;
    padding: 0 2rem;

    table {
        border-collapse: collapse;
    }
    th {
        padding: 0.8rem;
        background-color: #dddddd;
    }
    tr {
        border: 1px solid #dddddd;
        width: 100%;
    }
    td {
        padding: 1rem;
    }
`

const DesktopDashboard = styled.div`

    main {

        display: flex;
        // flex-direction: column;
    }
`

const MainSection = styled.div`
    margin: 0 auto;
    padding: 2rem 0;
`

const DesktopInfo = styled.div`
    display: flex;
    justify-content: space-between;
    display: none;
`

const DesktopSearch = styled.form`
    display: flex;
    justify-content: flex-start;
    margin: 0 2rem;
    position: relative;

    input {
        width: 40%;
        padding: 0.5rem;
        border-radius: 1rem;
        border: 1px solid grey;
        outline: none;

        &:focus {
            border: 1px solid #404295;
        }
    }

    button {
        border: none;
        background: none;
        position: absolute;
        right: 62%;
        top: 22%;
    }
`

export default DonationPage