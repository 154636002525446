import React from 'react'
import styled from 'styled-components'
import useWindowSize from '../utils/useWindowSize'
import Footer from './Footer';
import Header from './Header';
import Navbar from './Navbar';

const Contact = () => {

    const { width } = useWindowSize();
    const breakpoint = 620;

    return (
        <>
        {width < breakpoint ? 
            <ContactContainer>
                <Navbar />
                <Heading>Have a Message?</Heading>
                {/* <FormContainer>
                    <ContactFormMobile>
                        <input type="text" placeholder="Full Name" />
                        <input type="text" placeholder="Email" />
                        <input type="text" placeholder="Subject" />
                        <textarea type="textfield" placeholder="Your Message" />
                        <button>Send Message</button>
                    </ContactFormMobile>
                </FormContainer> */}
                <ContactDetailsMobile>
                    {/* <div style={{margin: '0 auto', textAlign: 'center', width: '100%'}}> */}
                        <span>
                            <img src="./map-pin.png" alt="map pin" />
                        </span>
                            <p>No.4 LORD LUGARD STREET BEHIND TOTAL FILLING STATION, AREA 11, ASOKORO, ABUJA, FCT, NIGERIA</p>
                    {/* </div> */}
                    {/* <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', width: '100%'}}> */}
                        <div style={{width: "50%"}}>
                            <span>
                                <img src="./phone.png" alt="phone" />
                            </span>
                                <p>09073373676</p>
                        </div>
                        <div style={{width: "50%"}}>
                            <span>
                                <img src="./mail.png" alt="email" />
                            </span>
                                <p>contact@cheerbequest.com</p>
                        </div>
                    {/* </div> */}
                </ContactDetailsMobile>
                <Footer />
            </ContactContainer>

            
        
        :
            <ContactContainer>
                <Header />
                <Heading>Have a Message?</Heading>
                {/* <FormContainer>
                    <ContactForm>
                        <input type="text" placeholder="Full Name" />
                        <input type="text" placeholder="Email" />
                        <input type="text" placeholder="Subject" />
                        <textarea type="textfield" placeholder="Your Message" />
                        <button>Send Message</button>
                    </ContactForm>
                </FormContainer> */}
                <ContactDetails>
                    <div>
                        <span>
                            <img src="./map-pin.png" alt="map pin" />
                        </span>
                            <p>No.4 LORD LUGARD STREET BEHIND TOTAL FILLING STATION, AREA 11, ASOKORO, ABUJA, FCT, NIGERIA</p>
                    </div>
                    <div>
                        <span>
                            <img src="./phone.png" alt="phone" />
                        </span>
                            <p>09073373676</p>
                    </div>
                    <div>
                        <span>
                            <img src="./mail.png" alt="email" />
                        </span>
                            <p>contact@cheerbequest.com</p>
                    </div>
                </ContactDetails>
                <Footer />
            </ContactContainer> 
        }
        </>
    )
}

const ContactContainer = styled.div`
    display: flex;
    flex-direction: column;
`

// const FormContainer = styled.div`
//     display: flex;
//     justify-content: center;
//     padding: 1rem 2rem;
//     margin: 2rem 0;
// `

const Heading = styled.h3`
    text-align: center;
    color: #D9745B;
`

// const ContactFormMobile = styled.form`
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     width: 100%;

//     input {
//         padding: 1rem;
//         margin: 0.5rem 0;
//         border: 0.5px solid grey;
//         width: 100%;

//         &:hover {
//             border-color: #404295;
//         }

//     }
//     textarea {
//         height: 10rem;
//         padding: 1rem;
//         margin: 0.5rem 0;

//         &:hover {
//             border-color: #404295;
//         }
//     }

//     button {
//         width: 50%;
//         margin: 0 auto;
//         padding: 0.8rem;
//         background-color: #404295;
//         color: white;
//         border: none;

//         &:hover {
//             cursor: pointer;
//         }
//     }
// `

// const ContactForm = styled.form`
//     display: flex;
//     justify-content: center;
//     flex-direction: column;
//     width: 40%;

//     input {
//         padding: 1rem;
//         margin: 0.5rem 0;
//         border: 0.5px solid grey;

//         &:hover {
//             border-color: #404295;
//         }

//     }
//     textarea {
//         height: 10rem;
//         padding: 1rem;
//         margin: 0.5rem 0;

//         &:hover {
//             border-color: #404295;
//         }
//     }

//     button {
//         width: 30%;
//         margin: 0 auto;
//         padding: 0.8rem;
//         background-color: #404295;
//         color: white;
//         border: none;

//         &:hover {
//             cursor: pointer;
//         }
//     }
// `

const ContactDetails = styled.div`
    padding: 1rem 2rem;
    display: flex;
    justify-content: space-evenly;
    width: 100%;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        word-wrap: break-word;
        width: 50%;

        span {
            margin: 0 1rem;
        }
    }
`
const ContactDetailsMobile = styled.div`
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: 0.7rem;

    div {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        word-wrap: break-word;
        width: 50%;

        span {
            margin: 0 1rem;
        }
    }
`

export default Contact
