import { createSlice } from "@reduxjs/toolkit"
import { userSignup, userSignin, userLogout, userDonation, userVerifyDonation, campaignDetails } from "../actions/user.actions";

const UserInfoFromLS = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
const initialState = {
    user: UserInfoFromLS,
    campaign: [],
    requestStatus: "",
    requestError: "",
}

const signinState = {
    user: {},
    requestStatus: "",
    requestError: "",
}
const signupState = {
    newUser: {},
    requestStatus: "",
    requestError: "",
}
const donationState = {
    payment_redirect_url: "",
    payment_status: false,
    requestStatus: "",
    requestError: "",
}



export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: {
        [userLogout.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [userLogout.fulfilled]: (state, action) => {
            return {
                ...state,
                requestStatus: "success",
                requestError: ""
            }
        },
        [userLogout.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})

export const signinSlice = createSlice({
    name: 'user',
    initialState: signinState,
    reducers: {},
    extraReducers: {
        [userSignin.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [userSignin.fulfilled]: (state, action) => {
            return {
                ...state,
                user: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [userSignin.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})

export const signupSlice = createSlice({
    name: 'user',
    initialState: signupState,
    reducers: {},
    extraReducers: {
        [userSignup.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [userSignup.fulfilled]: (state, action) => {
            return {
                ...state,
                newUser: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [userSignup.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})


export const donationSlice = createSlice({
    name: 'user/donation',
    initialState: donationState,
    reducers: {},
    extraReducers: {
        [userDonation.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [userDonation.fulfilled]: (state, action) => {
            return {
                ...state,
                payment_redirect_url: action.payload.payment_redirect_url,
                requestStatus: "success",
                requestError: ""
            }
        },
        [userDonation.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})


export const verifyDonationSlice = createSlice({
    name: 'user/verify-donation',
    initialState: donationState,
    reducers: {},
    extraReducers: {
        [userVerifyDonation.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [userVerifyDonation.fulfilled]: (state, action) => {
            return {
                ...state,
                payment_status: action.payload.paid,
                requestStatus: "success",
                requestError: ""
            }
        },
        [userVerifyDonation.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})


export const getCampaignSlice = createSlice({
    name: 'user/campaign',
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [campaignDetails.pending]: (state, action) => {
            return {
                ...state,
                requestStatus: "pending",
                requestError: ""
            }
        },
        [campaignDetails.fulfilled]: (state, action) => {
            return {
                ...state,
                campaign: action.payload,
                requestStatus: "success",
                requestError: ""
            }
        },
        [campaignDetails.rejected]: (state, action) => {
            return {
                ...state,
                requestStatus: "failed",
                requestError: action.payload
            }
        }
    }
})
