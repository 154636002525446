import GlobalStyles from './components/styles/Global';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import HowItWorks from './pages/HowItWorks';
import CreateCampaign from './pages/CreateCampaign';
import FundSomeone from './pages/FundSomeone';
import Login from './pages/Login';
import AdminDashboard from './pages/AdminDashboard';
import Campaigns from './pages/Campaigns';
import MyCampaigns from './pages/users/MyCampaigns';
import ApproveCampaign from './pages/ApproveCampaign';
import WithdrawCampaign from './pages/WithdrawCampaign';
import ContactPage from './pages/ContactPage';
import Donations from './pages/Donations';
import Register from './pages/Register';
import DonationPage from './pages/users/DonationPage';
import Dashboard from './pages/users/Dashboard';
import CampaignDetails from './pages/CampaignDetails';
// import DonationPage from './pages/DonationPage';
import PaymentCallback from './components/PaymentCallback';

function App() {
  return (
    <>
      <GlobalStyles />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/create-campaign" element={<CreateCampaign />} />
        <Route path="/fund-someone" element={<FundSomeone />} />
        <Route path="/fund-someone/:id" element={<CampaignDetails />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/donations" element={<DonationPage />} />
        <Route path="/payment/callback" element={<PaymentCallback />} />

        {/* Dashboard routes: conditional rendering depending on what type of user is logged in */}
        <Route path="/admin/dashboard" element={<AdminDashboard />} />
        <Route path="/admin/campaigns" element={<Campaigns />} />
        <Route path="/admin/approve-campaign/:id" element={<ApproveCampaign />} />
        <Route path="/admin/withdraw-campaign/:id" element={<WithdrawCampaign />} />
        <Route path="/admin/donations" element={<Donations />} />

        {/* User Dashboard routes */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/donations" element={<DonationPage />} />
        <Route path="my-campaigns" element={<MyCampaigns />} />
      </Routes>
    </>
  );
}

export default App;
