import { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
// import Categories from '../components/Categories'
import Footer from '../components/Footer'
import Header from '../components/Header'
import Navbar from '../components/Navbar'
import useWindowSize from '../utils/useWindowSize'
import { useDispatch, useSelector } from 'react-redux'
import { getCampaigns } from '../state/actions/campaign.actions'
import { userDonation } from '../state/actions/user.actions'


const FundSomeone = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [amount, setAmount] = useState(0)
    const [modalConfig, setModalConfig] = useState({ display: false, campaignId: '' })

    const { width } = useWindowSize();
    const breakpoint = 620;

    const { campaigns } = useSelector(state => state.campaigns)
    const { user } = useSelector(state => state.auth)
    const { payment_redirect_url } = useSelector(state => state.donation)


    useEffect(() => {
        if (!user) navigate('/', { replace: true })
        if (payment_redirect_url) window.location.href = payment_redirect_url
        dispatch(getCampaigns())
    }, [dispatch, navigate, user, payment_redirect_url])

    const donateToCampaign = (campaignId) => {
        if (amount <= 0) return

        const { fullname, email } = user?.user

        dispatch(userDonation({ fullname, email, amount, campaignId, split_code: process.env.REACT_APP_PAYSTACK_SPLIT_CODE }))
        localStorage.setItem('campaignId', JSON.stringify(campaignId))

        setAmount(0)
        setModalConfig({ ...modalConfig, display: false, campaignId: '' })
    }



    return (
        <div>
            {width < breakpoint ?
                <>
                    <Navbar />
                    <Heading>Fund Someone</Heading>
                    {/* <CategorySection>
                        <div>
                            <img src="./health.png" alt="" />
                            <figcaption>Health</figcaption>
                        </div>
                        <div>
                            <img src="./education.png" alt="" />
                            <figcaption>Education</figcaption>
                        </div>
                        <div>
                            <img src="./agriculture.png" alt="" />
                            <figcaption>Agriculture</figcaption>
                        </div>
                        <div>
                            <img src="./community.png" alt="" />
                            <figcaption>Community Dev.</figcaption>
                        </div>
                        <div>
                            <img src="./youth-emp.png" alt="" />
                            <figcaption>Youth Empowerment</figcaption>
                        </div>
                        <div>
                            <img src="./elders-interv.png" alt="" />
                            <figcaption>Elders Intervention</figcaption>
                        </div>
                        <div>
                            <img src="./girl-child.png" alt="" />
                            <figcaption>Girl Child</figcaption>
                        </div>
                        <div>
                            <img src="./less-priv.png" alt="" />
                            <figcaption>Less Priveleged</figcaption>
                        </div>
                        <div>
                            <img src="./disaster-int.png" alt="" />
                            <figcaption>Disaster Intervention</figcaption>
                        </div>
                        <div>
                            <img src="./sports.png" alt="" />
                            <figcaption>Sports Support</figcaption>
                        </div>
                        <div>
                            <img src="./religion.png" alt="" />
                            <figcaption>Religion</figcaption>
                        </div>
                        <div>
                            <img src="./politics.png" alt="" />
                            <figcaption>Politics</figcaption>
                        </div>
                        <div>
                            <img src="./hospitality.png" alt="" />
                            <figcaption>Hospitality</figcaption>
                        </div>
                        <div>
                            <img src="./crisis-peace.png" alt="" />
                            <figcaption style={{ width: '100px' }}>Crisis-Peace Intervention</figcaption>
                        </div>
                    </CategorySection>
                    <Heading>Health</Heading> */}
                    <CategoryCards>
                        {campaigns ? campaigns.map(campaign => (
                            <div key={campaign._id}>
                                <CardImage>
                                    <img src={campaign.imageUrl} alt="category" />
                                </CardImage>
                                <CardBody>
                                    <p>{campaign.title}</p>
                                    <div>
                                        <p>{campaign.category.split(',')[0]}</p>
                                        <p>	&#8358;{campaign.fundingGoal}</p>
                                    </div>
                                    {/* <input type="number" placeholder="amount" value={amount} onChange={(e) => setAmount(e.target.value)} /> */}
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        {campaign.approved && (
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
                                                <input
                                                    type="number"
                                                    placeholder="amount"
                                                    value={amount}
                                                    onChange={(e) => setAmount(e.target.value)}
                                                    style={{
                                                        width: '100%',
                                                        padding: '10px',
                                                        marginBottom: '10px',
                                                    }}
                                                />
                                                <button
                                                    onClick={() => donateToCampaign(campaign._id)}
                                                    style={{ cursor: 'pointer', width: '100%', marginBottom: '10px' }}
                                                >Donate</button>
                                            </div>
                                        )}
                                        <NavLink to={`/fund-someone/${campaign._id}`}>See Details &gt;</NavLink>
                                    </div>
                                </CardBody>
                            </div>
                        )) : (
                            <div>
                                <CardImage>
                                    <img src="./donor-1.png" alt="category" />
                                </CardImage>
                                <CardBody>
                                    <p>Joyce Black</p>
                                    <div>
                                        <p>Kidney Failure</p>
                                        <p>$100,000</p>
                                    </div>
                                    <div>
                                        <button>Donate</button>
                                        <NavLink to="#">See Details &gt;</NavLink>
                                    </div>
                                </CardBody>
                            </div>
                        )}

                        {/* <div>
                            <CardImage>
                                <img src="./donor-1.png" alt="category" />
                            </CardImage>
                            <CardBody>
                                <p>Joyce Black</p>
                                <div>
                                    <p>Kidney Failure</p>
                                    <p>$100,000</p>
                                </div>
                                <div>
                                    <button>Donate</button>
                                    <NavLink to="#">See Details &gt;</NavLink>
                                </div>
                            </CardBody>
                        </div>

                        <div>
                            <CardImage>
                                <img src="./donor-1.png" alt="category" />
                            </CardImage>
                            <CardBody>
                                <p>Joyce Black</p>
                                <div>
                                    <p>Kidney Failure</p>
                                    <p>$100,000</p>
                                </div>
                                <div>
                                    <button>Donate</button>
                                    <NavLink to="#">See Details &gt;</NavLink>
                                </div>
                            </CardBody>
                        </div>

                        <div>
                            <CardImage>
                                <img src="./donor-1.png" alt="category" />
                            </CardImage>
                            <CardBody>
                                <p>Joyce Black</p>
                                <div>
                                    <p>Kidney Failure</p>
                                    <p>$100,000</p>
                                </div>
                                <div>
                                    <button>Donate</button>
                                    <NavLink to="#">See Details &gt;</NavLink>
                                </div>
                            </CardBody>
                        </div> */}
                    </CategoryCards>
                    <Pagination>
                        <button>1</button>
                        <button>2</button>
                        <button>...</button>
                    </Pagination>
                </>

                :

                <>
                    <Header />
                    {/* <Categories heading="Funding Categories" /> */}
                    <HeadingDesktop>Fund Someone</HeadingDesktop>
                    <CategoryCardsDesktop>
                        {campaigns ? campaigns?.map(campaign => (
                            <div key={campaign._id}>
                                <CardImage>
                                    <img src={campaign.imageUrl} alt="category" />
                                </CardImage>
                                <CardBody>
                                    <p>{campaign.title}</p>
                                    <div>
                                        <p>{campaign.category.split(',')[0]}</p>
                                        <p>	&#8358;{campaign.fundingGoal}</p>
                                    </div>
                                    <div>
                                        {/* <button>Donate</button> */}

                                        {campaign.approved && (
                                            <button
                                                onClick={() => setModalConfig({ ...modalConfig, display: true, campaignId: campaign._id })}
                                                style={{ cursor: 'pointer' }}
                                            >Donate</button>
                                        )}
                                        <NavLink to={`/fund-someone/${campaign._id}`}>See Details &gt;</NavLink>
                                    </div>

                                </CardBody>
                            </div>
                        )) : (
                            <div>
                                <CardImage>
                                    <img src="./donor-1.png" alt="category" />
                                </CardImage>
                                <CardBody>
                                    <p>Joyce Black</p>
                                    <div>
                                        <p>Kidney Failure</p>
                                        <p>$100,000</p>
                                    </div>
                                    <div>
                                        <button>Donate</button>
                                        <NavLink to="#">See Details &gt;</NavLink>
                                    </div>
                                </CardBody>
                            </div>
                        )}

                        {/* MODAL */}
                        {modalConfig.display && (
                            <Modal>
                                <ModalBody>
                                    <div>
                                        <i
                                            className="fa fa-times-circle modal-close"
                                            onClick={() => setModalConfig({ ...modalConfig, display: false, campaignId: '' })}
                                        ></i>
                                        <input
                                            type="number"
                                            placeholder="Enter Amount in NGN"
                                            value={amount}
                                            onChange={(e) => setAmount(e.target.value)}
                                        />
                                        <button onClick={() => donateToCampaign(modalConfig.campaignId)} style={{ cursor: 'pointer' }}>Donate</button>
                                    </div>
                                </ModalBody>
                            </Modal>
                        )}

                        {/* <div>
                        <CardImage>
                            <img src="./donor-1.png" alt="category" />
                        </CardImage>
                        <CardBody>
                            <p>Joyce Black</p>
                            <div>
                                <p>Kidney Failure</p>
                                <p>$100,000</p>
                            </div>
                            <div>
                                <button>Donate</button>
                                <NavLink to="#">See Details &gt;</NavLink>
                            </div>
                        </CardBody>
                    </div>

                    <div>
                        <CardImage>
                            <img src="./donor-1.png" alt="category" />
                        </CardImage>
                        <CardBody>
                            <p>Joyce Black</p>
                            <div>
                                <p>Kidney Failure</p>
                                <p>$100,000</p>
                            </div>
                            <div>
                                <button>Donate</button>
                                <NavLink to="#">See Details &gt;</NavLink>
                            </div>
                        </CardBody>
                    </div>

                    <div>
                        <CardImage>
                            <img src="./donor-1.png" alt="category" />
                        </CardImage>
                        <CardBody>
                            <p>Joyce Black</p>
                            <div>
                                <p>Kidney Failure</p>
                                <p>$100,000</p>
                            </div>
                            <div>
                                <button>Donate</button>
                                <NavLink to="#">See Details &gt;</NavLink>
                            </div>
                        </CardBody>
                    </div>

                    <div>
                        <CardImage>
                            <img src="./donor-1.png" alt="category" />
                        </CardImage>
                        <CardBody>
                            <p>Joyce Black</p>
                            <div>
                                <p>Kidney Failure</p>
                                <p>$100,000</p>
                            </div>
                            <div>
                                <button>Donate</button>
                                <NavLink to="#">See Details &gt;</NavLink>
                            </div>
                        </CardBody>
                    </div> */}
                    </CategoryCardsDesktop>
                    <Pagination>
                        <button>1</button>
                        <button>2</button>
                        <button>...</button>
                    </Pagination>
                </>
            }
            <Footer />
        </div>
    )
}

// const CategorySection = styled.section`
//     display: grid;
//     grid-template-columns: 1fr 1fr 1fr;
//     grid-template-rows: 1fr 1fr 1fr;
//     column-gap: 3rem;
//     row-gap: 3rem;
//     margin: 6rem 2rem;
//     // padding: 2rem 10rem;

//     div {
//         width: 50px;
//         height: 50px;
//         text-align: center;

//         img {
//             width: 100%;
//             height: 100%;
//         }
//     }
// `

const CategoryCardsDesktop = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 2rem 2rem;

`

const Heading = styled.h3`
    text-align: center;
    color: #D9745B;
    margin: 2rem 0;
`
const HeadingDesktop = styled.h3`
    text-align: center;
    color: #D9745B;
    margin: 2rem 4rem;
`

const CategoryCards = styled.section`
    display: flex;
    flex-direction: column;
    
`

const CardImage = styled.div`
    padding: 0 2rem;

    img {
        width: 100%;
    }
`
const CardBody = styled.div`
    display: flex; 
    flex-direction: column;
    padding: 0 2rem;
    margin-bottom: 2rem;
    input{
        width: 100%;
        padding: 10px 20px 20px;
        border-radius: 5px;
        outline: none;
        border: 1px solid black;
        background: gray;
        color: white;
        font-size: 20px;
        margin-bottom: 10px;
        &::placeholder{
            color: white;
        }
    }

    div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        a {
            text-decoration: none;
        }
        button {
            padding: 0.5rem 2rem;
            border: 1px solid #404295;
            color: #404295;
            background-color: white;
        }
    }
`

export const Pagination = styled.div`
    display: flex;
    justify-content: center;

    
    button {
        border: 1px solid #404295;
        padding: 0.5rem;
        margin: 1rem 0.3rem;
        background-color: white;
        color: #404295;
    }
`

const Modal = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    z-index: 999;
    position: fixed;
	top: 0;
	left: 0;
`


const ModalBody = styled.div`
    display: flex;
    align-items: center;
    text-align: center;
    margin: auto;
    border-radius: 10px;
    color: white;
    background: linear-gradient(to right, #404295, #3c1053);
    justify-content: center;
    width: 450px;
    height: 200px;
    font-family: 'Nunito';
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
    transition: 0.3s ease;
    div {
        padding: 20px 20px 40px;
        .modal-close {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 20px;
            font-size: 1.2em;
            cursor: pointer;
        }
        input{
            width: 80%;
            outline: none;
            padding: 15px 20px 25px;
            margin-bottom: 10px;
            font-size: 25px;
            border-radius: 10px;
            background: rgba(0, 0, 0, .5);
            color: white;
            font-weight: 600;
            border: none;
        }
        button {
            color: white;
            padding: 10px;
            background: white;
            width: 40%;
            margin: auto;
            color: #283c86; 
            border-radius: 10px;
            border: none;
            transition: .3s ease;
            font-weight: 600;
            font-size: 20px;
            &:hover{
                transform: translateY(5px);
            }
        }
    }
`


export default FundSomeone
