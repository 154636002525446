import React from 'react'
import { FooterStyle } from "./styles/Footer.styled";
import { NavLink } from 'react-router-dom'
import styled from "styled-components";
import useWindowSize from '../utils/useWindowSize'


const Footer = () => {

    const { width } = useWindowSize()
    const breakpoint = 620;

    return (
        <>
        {width < breakpoint ? 
            <FooterNavMobile>
                <div>
                    <h4>Cheer Bequest</h4>
                    <p>
                    The Crowdfunding that got you covered, Always Secure and Transparent.
                    Get started with us today and raise funds for your emergencies and dreams
                    </p>
                </div>
                <nav>
                    <section>
                        <h5>LEARN MORE</h5>
                        <div>
                            <NavLink to="/" activeclassname="current" exact><li>How Cheer Bequest Works</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Success Stories</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>FAQ</li></NavLink>
                        </div>
                    </section>
                    <section>
                        <h5>FUND RAISE FOR</h5>
                        <div>
                            <NavLink to="/" activeclassname="current" exact><li>Health</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Education</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Agriculture</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Community Development</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Youth Empowerment</li></NavLink>
                        </div>
                    </section>
                    <section>
                        <h5>RESOURCES</h5>
                        <div>
                            <NavLink to="/" activeclassname="current" exact><li>About Us</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Contact Us</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Privacy Policy</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Terms & Conditions</li></NavLink>
                            <NavLink to="/" activeclassname="current" exact><li>Partnership</li></NavLink>
                        </div>
                    </section>
                </nav>
            </FooterNavMobile>
        
        : 
                <FooterStyle>
                    <FooterNav>
                        <div>
                            <h4>Cheer Bequest</h4>
                            <p>
                            The Crowdfunding that got you covered, Always Secure and Transparent.
                            Get started with us today and raise funds for your emergencies and dreams
                            </p>
                        </div>
                        <nav>
                            <section>
                                <h5>LEARN MORE</h5>
                                <div>
                                    <NavLink to="/" activeclassname="current" exact><li>How Cheer Bequest Works</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Success Stories</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>FAQ</li></NavLink>
                                </div>
                            </section>
                            <section>
                                <h5>FUND RAISE FOR</h5>
                                <div>
                                    <NavLink to="/" activeclassname="current" exact><li>Health</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Education</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Agriculture</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Community Development</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Youth Empowerment</li></NavLink>
                                </div>
                            </section>
                            <section>
                                <h5>RESOURCES</h5>
                                <div>
                                    <NavLink to="/" activeclassname="current" exact><li>About Us</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Contact Us</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Privacy Policy</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Terms & Conditions</li></NavLink>
                                    <NavLink to="/" activeclassname="current" exact><li>Partnership</li></NavLink>
                                </div>
                            </section>
                        </nav>
                    </FooterNav>
                </FooterStyle>
            }
            {width < breakpoint ? 
                <MobileCopyrightFooter>
                    <div> Copyright © Cheer Bequest 2021 </div>
                    <Socials>
                        <span>
                            <a href="https://twitter.com/CheerBequest" target
                            ="_blank" rel="noreferrer"><img src="/twitter.png" alt="twitter" /></a>
                        </span>
                        <span>
                            <a href="https://m.facebook.com/cheerbequestltd/" target
                            ="_blank" rel="noreferrer"><img src="/facebook.png" alt="facebook" /></a>
                        </span>
                        <span>
                            <a href="https://twitter.com/CheerBequest" target
                            ="_blank" rel="noreferrer"><img src="/linkedin.png" alt="linkedin" /></a>
                        </span>
                        <span>
                            <a href="https://instagram.com/cheerbequest?utm_medium=copy_link" target
                            ="_blank" rel="noreferrer"><img src="/ig.png" alt="instagram" /></a>
                        </span>
                        <span>
                            <a href="https://www.youtube.com/channel/UCWZeISkOmt3Wt13AtQtrU5Q" target
                            ="_blank" rel="noreferrer"><img src="/youtube.png" alt="youtube" /></a>
                        </span>
                        <span>
                            <a href="https://medium.com/@cheerbequest" target
                            ="_blank" rel="noreferrer"><img src="/medium.png" alt="medium" /></a>
                        </span>
                    </Socials>
                </MobileCopyrightFooter> 
            
            :
                <CopyrightFooter>
                    <div> Copyright © Cheer Bequest 2021 </div>
                    <Socials>
                        <span>
                            <a href="https://twitter.com/CheerBequest" target
                            ="_blank" rel="noreferrer"><img src="/twitter.png" alt="twitter" /></a>
                        </span>
                        <span>
                            <a href="https://m.facebook.com/cheerbequestltd/" target
                            ="_blank" rel="noreferrer"><img src="/facebook.png" alt="facebook" /></a>
                        </span>
                        <span>
                            <a href="https://twitter.com/CheerBequest" target
                            ="_blank" rel="noreferrer"><img src="/linkedin.png" alt="linkedin" /></a>
                        </span>
                        <span>
                            <a href="https://instagram.com/cheerbequest?utm_medium=copy_link" target
                            ="_blank" rel="noreferrer"><img src="/ig.png" alt="instagram" /></a>
                        </span>
                        <span>
                            <a href="https://www.youtube.com/channel/UCWZeISkOmt3Wt13AtQtrU5Q" target
                            ="_blank" rel="noreferrer"><img src="/youtube.png" alt="youtube" /></a>
                        </span>
                        <span>
                            <a href="https://medium.com/@cheerbequest" target
                            ="_blank" rel="noreferrer"><img src="/medium.png" alt="medium" /></a>
                        </span>
                    </Socials>
                </CopyrightFooter>
            }
        </>
    )
}

const FooterNav = styled.section`
    padding: 2rem 0;

    div {
        text-align: center;

        h4 {
            color: #D9745B;
        }
    }

    nav {
        display: flex;
        justify-content: space-between;

        h5 {
            color: #D9745B;
        }

        div {
            list-style: none;
            text-align: left;
            
            a {
                text-decoration: none;
                color: black;
            }

            li {
                margin-bottom: 10px;
            }
        }
    }
`

const FooterNavMobile = styled.section`
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 0;
    text-align: center;
    background-color: #F4F5FF;
    font-size: 0.9rem;

    h4 {
        color: #D9745B;
    }

    nav {
        display: flex;
        flex-direction: column;

        a {
            text-decoration: none;
            color: black;
        }
        h5 {
            color: #D9745B;
        }
        li {
            list-style: none;
        }
    }
`

const CopyrightFooter = styled.section`
    background-color: #404295;
    padding: 1rem 2rem;
    margin-bottom: 0;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;

`
const MobileCopyrightFooter = styled.section`
    background-color: #404295;
    padding: 1rem 2rem;
    margin-bottom: 0;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    Socials {
        span {
            width: 40px;
            height: 40px;
        }
    }
`

const Socials = styled.div`
    display: flex;
    align-items: flex-end;
    
    span {
        margin: 0 1rem;
    }
`

// const FooterNavList = styled.ul`

// a {
//     text-decoration: none;
//     color: black;
// }

// li {
//         text-align: center;
//         list-style: none;
//     }
// `

export default Footer

