import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { userVerifyDonation } from "../state/actions/user.actions"
import styled from 'styled-components'
import Loader from './Loader/Loader'
import { GiCheckMark } from 'react-icons/gi'

const PaymentCallback = () => {
    const [params] = useSearchParams()
    const [verified, setVerified] = useState(false)

    const dispatch = useDispatch()
    const ref = params.get('reference')

    const campaignId = localStorage.getItem('campaignId') ? JSON.parse(localStorage.getItem('campaignId')) : null

    const { payment_status } = useSelector(state => state.verifyDonation)


    useEffect(() => {
        if (!payment_status) dispatch(userVerifyDonation({ ref, campaignId }))
        setTimeout(() => {
            if (payment_status) setVerified(true)
        }, 6000)
    }, [campaignId, dispatch, ref, payment_status])

    return (
        <PaymentModal>
            <PaymentModalBody verified>
                {!payment_status && (
                    <Loader />
                )}

                {verified && (
                    <div>
                        <h1>
                            payment verified <br />
                            <GiCheckMark />
                        </h1>
                        <p onClick={() => window.location.href = '/fund-someone'}>redirect</p>
                    </div>
                )}
            </PaymentModalBody>
        </PaymentModal>
    )
}


const PaymentModal = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.1);
    backdrop-filter: blur(10px);
`


const PaymentModalBody = styled.div`
    margin: auto;
    width: ${props => props.verified ? 350 : 200}px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    text-align: center;
    color: white;
    background: linear-gradient(to right, #aa076b, #61045f);
    justify-content: center;
    height: 200px;
    padding: 10px 20px;
    font-family: 'Nunito';
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.3);
    transition: 0.3s ease;
    &:hover {
        transform: scale(1.02);
    }
    p {
        color: white;
        padding: 5px 10px;
        background: white;
        width: 40%;
        margin: auto;
        color: #283c86; 
        border-radius: 10px;
        cursor: pointer;
        transition: .3s ease;
        &:hover{
            transform: translateX(5px);
        }
    }
`

export default PaymentCallback
