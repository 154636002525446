import React from 'react'
import styled from 'styled-components';

const AchievementCard = ({img}) => {
    return (
        <Card>
            <div style={{}}>
                <img style={{width: '100%', height: '100%'}} src={img} alt="achievement" />
            </div>
            <div style={{paddingTop: "2rem"}}>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Laoreet egestas vulputate iaculis turpis et nibh malesuada. Neque fermentum mattis auctor mi. Non eget nec, nam risus purus libero, vulputate. Quis eget hac maecenas quisque amet, fringilla. Euismod enim venenatis sollicitudin laoreet tristique. Volutpat commodo pretium nibh id rutrum. Eu consequat diam ipsum facilisis...</p>
            </div>
        </Card>
    )
}

const Card = styled.div`
    display: flex;
    flex-direction: column;
`

export default AchievementCard
